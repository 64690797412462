import * as React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import styled from "styled-components"
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import ButtonC from "./elements/button"

const AnnualStyle = styled.section`
  padding: 0px 24px;

  margin-bottom: 24px;
  @media (min-width: 1024px) {
    margin-bottom: 24px;
  }

  @media (min-width: 1440px) {
    margin-bottom: 0;
  }

  .background {
    height: 392px;
    width: 100%;
    background: linear-gradient(
      0deg,
      rgba(5, 64, 10, 0.75),
      rgba(5, 64, 10, 0.75)
    );
    border-radius: 8px;
    overflow: hidden;
    margin-top: 24px;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .background-gradient {
    height: 392px;
    width: 100%;
    background: linear-gradient(
      0deg,
      rgba(5, 64, 10, 0.75),
      rgba(5, 64, 10, 0.75)
    );
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .inner-holder {
    margin: 0px 40px;
  }

  button {
    width: auto;
    padding: 0 24px;
    height: 48px;
    background: #fcfdfc;
    color: #05400a;
    border-radius: 4px;
    border: none;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
  }

  .question {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #a3d9a5;
    margin: 32px 0px;
    margin-top: 0;
  }

  .statement {
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    color: #fcfdfc;
    margin: 48px 0px;
    text-transform: capitalize;
  }

  .video {
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    font-feature-settings: "cpsp" on;
    color: #fcfdfc;
    margin: 24px 10px;
    margin-top: 8px;
  }

  button:hover {
    background: #fcfdfc;
    color: #57ae5b;
  }

  button:active {
    background: #fcfdfc;
    box-shadow: inset 0px 2px 2px rgba(32, 114, 39, 0.25);
    color: #57ae5b;
  }

  @media (max-width: 374px) {
    .question {
      margin: 16px 0;
      margin-top: 0;
    }
    .statement {
      margin: 32px 0;
    }
  }

  @media (min-width: 768px) {
    padding: 0 48px;

    .mobile {
      display: none;
    }

    .question {
      font-weight: bold;
      font-size: 18px;
      line-height: 24px;
      margin-top: 48px;
      margin-top: 0;
    }

    .video {
      margin-left: 24px;
      margin-bottom: 48px;
    }

    .statement {
      width: 510px;
      font-size: 40px;
      line-height: 48px;
    }

    .background {
      background: linear-gradient(
        0deg,
        rgba(5, 64, 10, 0.75),
        rgba(5, 64, 10, 0.75)
      );
    }
  }

  @media (min-width: 1024px) {
    .background {
      margin-top: 24px;
    }
  }
  @media (min-width: 1440px) {
    .background,
    .background-gradient {
      width: 1376px;
      height: 432px;
      margin: 32px 0px;
      border-radius: 8px;
      overflow: hidden;
      background: linear-gradient(
        0deg,
        rgba(5, 64, 10, 0.75),
        rgba(5, 64, 10, 0.75)
      );
    }

    .background {
      margin-top: 32px;
    }

    .background-gradient {
      margin: 0;
    }

    .statement {
      font-size: 48px;
      line-height: 56px;
      width: 600px;
    }

    .inner-holder {
      margin-left: 40px;
    }

    button {
      font-weight: bold;
    }

    .question {
      margin-top: 64px;
      margin-top: 0;
    }

    .video {
      margin-bottom: 64px;
    }
  }
`

const Cta = () => {
  const { backgroundImage123 } = useStaticQuery(
    graphql`
      query {
        backgroundImage123: file(relativePath: { eq: "CTA.png" }) {
          childImageSharp {
            gatsbyImageData(height: 2499, width: 1667, quality: 80)
          }
        }
      }
    `
  )

  const image = getImage(backgroundImage123)

  return (
    <AnnualStyle>
      <div>
        <BgImage image={image} className="background">
          <div className="background-gradient">
            <div className="inner-holder">
              <div className="question">
                Ready to improve your <br className="mobile" /> profitability
                and productivity?
              </div>
              <div className="statement">
                Unlock the full potential of your facility. today!
              </div>

              <div className="schedule">
                {/* <Link to="/schedule">
                  <button>Contact us</button>
                </Link> */}
                <ButtonC
                  size="big"
                  color="white"
                  text="Contact Us"
                  // action=""
                  link="/schedule"
                />
                {/* <div className="video">*It only takes few minutes.</div> */}
              </div>
            </div>
          </div>
        </BgImage>
      </div>
    </AnnualStyle>
  )
}

export default Cta
