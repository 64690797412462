import * as React from "react"
import styled from "styled-components"

const AnnualStyle = styled.section`
  color: #423d33;

  .faq-holder {
    background: none;
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    align-items: center;
  }

  h3 {
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    margin: 40px 0px;
  }

  .question-box,
  button {
    display: flex;
    flex-direction: row;
    text-align: left;
    align-items: center;
  }

  .qa-holder {
    width: 100%;
    margin-right: 16px;
    margin-left: 16px;
  }

  .two-columns {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: start;
    margin-right: 32px;
    margin-left: 32px;
  }

  .arrow-open {
    margin-right: 16px;
    margin-top: 2px;
    margin-bottom: 2px;
  }

  .arrow-closed {
    margin-right: 20px;
  }

  button,
  input[type="submit"],
  input[type="reset"] {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }

  .answer {
    margin: 16px 0px;
    width: 320px;
    font-size: 18px;
    line-height: 24px;
  }

  .question {
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    font-feature-settings: "ss04" on, "ss03" on, "ss02" on, "ss06" on, "ss07" on;
  }

  .more-questions {
    font-weight: normal;
    text-align: center;
    margin-top: 48px;

    font-size: 18px;
    line-height: 24px;
    font-feature-settings: "ss04" on, "ss03" on, "ss02" on, "ss06" on, "ss07" on;
    margin-bottom: 40px;
    margin-left: 48px;
    margin-right: 48px;
    width: 100%;
  }

  a {
    font-weight: bold;
    text-decoration: underline !important;
  }

  @media (max-width: 767px) {
    .faq-holder {
      display: none !important;
    }
  }

  @media (min-width: 768px) {
    h3 {
      font-weight: bold;
      font-size: 40px;
      line-height: 48px;
      margin-bottom: 48px;
      margin-top: 32px;
    }
  }

  @media (min-width: 1024px) {
    .qa-holder {
      width: 428px;
    }
    .answer {
      width: 100%;
    }

    .question {
      width: 100%;
    }
  }

  @media (min-width: 1440px) {
    .more-questions {
      margin-top: 64px;
    }
    .qa-holder {
      width: auto;
    }

    .two-columns {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-right: 32px;
      margin-left: 32px;
    }

    .answer {
      margin: 16px 0px;
      width: 544px;
      font-size: 18px;
      line-height: 24px;
    }

    .question {
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      font-feature-settings: "ss04" on, "ss03" on, "ss02" on, "ss06" on,
        "ss07" on;
    }

    .faq-holder {
      width: 1376px;
      border-radius: 8px;
    }

    h3 {
      font-weight: bold;
      font-size: 48px;
      line-height: 56px;
      margin-bottom: 64px;
    }
  }

  hr {
    background: #b8b2a7;
    opacity: 1;
  }
`

const FAQOther = () => {
  /// Question 1
  let question1 = (
    <div className="question">What are concurrent activations?</div>
  )
  let answer1 = (
    <div className="answer">
      One activation is connected to one machine. You can remove an activation
      to activate it on a different machine anytime. Read <a href="#">here</a>{" "}
      more about it.
    </div>
  )

  ///Question 2
  let question2 = (
    <div className="question">
      How do I upgrade from a FREE to a pro license?
    </div>
  )
  let answer2 = (
    <div className="answer">
      One activation is connected to one machine. You can remove an activation
      to activate it on a different machine anytime. Read <a href="#">here</a>{" "}
      more about it.
    </div>
  )

  ///Question 3
  let question3 = <div className="question">How do I use ASCALIA with ...?</div>
  let answer3 = (
    <div className="answer">
      One activation is connected to one machine. You can remove an activation
      to activate it on a different machine anytime. Read <a href="#">here</a>{" "}
      more about it.
    </div>
  )

  ///Question 4
  let question4 = <div className="question">Technical Support </div>
  let answer4 = (
    <div className="answer">
      One activation is connected to one machine. You can remove an activation
      to activate it on a different machine anytime. Read <a href="#">here</a>{" "}
      more about it.
    </div>
  )

  ///Question 5
  let question5 = <div className="question">What is Ascalia?</div>
  let answer5 = (
    <div className="answer">
      One activation is connected to one machine. You can remove an activation
      to activate it on a different machine anytime. Read <a href="#">here</a>{" "}
      more about it.
    </div>
  )

  ///Question 6
  let question6 = <div className="question">Where can i Download ASCALIA?</div>
  let answer6 = (
    <div className="answer">
      One activation is connected to one machine. You can remove an activation
      to activate it on a different machine anytime. Read <a href="#">here</a>{" "}
      more about it.
    </div>
  )

  ///Question 7
  let question7 = (
    <div className="question">How Do i Use FEATURE ... IN ASCALIA?</div>
  )
  let answer7 = (
    <div className="answer">
      One activation is connected to one machine. You can remove an activation
      to activate it on a different machine anytime. Read <a href="#">here</a>{" "}
      more about it.
    </div>
  )

  ///Question 8
  let question8 = <div className="question">Sales Support</div>
  let answer8 = (
    <div className="answer">
      One activation is connected to one machine. You can remove an activation
      to activate it on a different machine anytime. Read <a href="#">here</a>{" "}
      more about it.
    </div>
  )

  ///Question 9 - Uncomment
  // let question9 = <div className="question">Technical Support</div>
  // let answer9 = (
  //   <div className="answer">
  //     One activation is connected to one machine. You can remove an activation
  //     to activate it on a different machine anytime. Read here more about it.
  //   </div>
  // )

  return (
    <AnnualStyle>
      <div className="faq-holder">
        <h3>Frequently asked questions</h3>
        <div className="two-columns">
          <div className="qa-holder">
            <div className="question-box">{question1}</div>
            {answer1}
            <hr></hr>
            <div className="question-box">{question2}</div>
            {answer2}
            <hr></hr>
            <div className="question-box">{question3}</div>
            {answer3}
            <hr></hr>
            <div className="question-box">{question4}</div>
            {answer4}
          </div>
          <div className="qa-holder">
            <div className="question-box">{question5}</div>
            {answer5}
            <hr></hr>
            <div className="question-box">{question6}</div>
            {answer6}
            <hr></hr>
            <div className="question-box">{question7}</div>
            {answer7}
            <hr></hr>
            <div className="question-box">{question8}</div>
            {answer8}
          </div>
        </div>
        <div className="more-questions">
          Still have some questions? <a href="#">Email</a> us, or{" "}
          <a href="#">give us a call</a>. We are happy to help you!
        </div>
      </div>
    </AnnualStyle>
  )
}

export default FAQOther
