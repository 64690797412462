import * as React from "react"
import styled from "styled-components"
import { useState } from "react"
import Open from "../images/faq/open.svg"
import Closed from "../images/faq/closed.svg"

const AnnualStyle = styled.section`
  color: #423d33;
  .faq-holder {
    background: none;
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    align-items: center;
  }

  h3 {
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    margin: 40px 0px;
  }

  button {
    display: inline;
    flex-direction: row;
    text-align: left;
    align-items: left;
    /* white-space: nowrap; */
    /* height: 64px; */
  }

  .qa-holder {
    width: 327px;
  }

  .arrow-open {
    width: 12px;
    height: 8px;
    transform: translateY(24px);
  }

  .arrow-closed {
    width: 8px;
    height: 13px;
    transform: translateY(24px);
  }

  button,
  input[type="submit"],
  input[type="reset"] {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    transform: translateY(-12px);
  }

  .answer {
    margin: 16px 0px;
  }

  .question {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    margin-left: 40px;
    /* font-feature-settings: "ss04" on, "ss03" on, "ss02" on, "ss06" on, "ss07" on; */
  }

  .more-questions {
    font-weight: normal;
    font-size: 18px;
    text-align: center;
    line-height: 24px;
    font-feature-settings: "ss04" on, "ss03" on, "ss02" on, "ss06" on, "ss07" on;
    margin: 40px 0px;
  }

  a {
    font-weight: bold;
    text-decoration: underline !important;
  }

  @media (min-width: 768px) {
    .faq-holder {
      display: none !important;
    }
  }
`

const FAQMobile = () => {
  const [open1, setOpen1] = useState(() => false)
  const [open2, setOpen2] = useState(() => false)
  const [open3, setOpen3] = useState(() => false)
  const [open4, setOpen4] = useState(() => false)
  const [open5, setOpen5] = useState(() => false)
  const [open6, setOpen6] = useState(() => false)
  const [open7, setOpen7] = useState(() => false)
  const [open8, setOpen8] = useState(() => false)
  const [open9, setOpen9] = useState(() => false)

  /// Question 1
  let question1 = (
    <div className="question">What are concurrent activations?</div>
  )
  let answer1 = (
    <div className="answer">
      One activation is connected to one machine. You can remove an activation
      to activate it on a different machine anytime.{" "}
      <a href="#">Here, read here more about it.</a>
    </div>
  )

  ///Question 2
  let question2 = (
    <div className="question">
      How do I upgrade from a FREE to a pro license?
    </div>
  )
  let answer2 = (
    <div className="answer">
      One activation is connected to one machine. You can remove an activation
      to activate it on a different machine anytime.{" "}
      <a href="#">Here, read here more about it.</a>
    </div>
  )

  ///Question 3
  let question3 = <div className="question">How do I use ASCALIA with ...?</div>
  let answer3 = (
    <div className="answer">
      One activation is connected to one machine. You can remove an activation
      to activate it on a different machine anytime.{" "}
      <a href="#">Here, read here more about it.</a>
    </div>
  )

  ///Question 4
  let question4 = <div className="question">Technical Support </div>
  let answer4 = (
    <div className="answer">
      One activation is connected to one machine. You can remove an activation
      to activate it on a different machine anytime.{" "}
      <a href="#">Here, read here more about it.</a>
    </div>
  )

  ///Question 5
  let question5 = <div className="question">What is Ascalia?</div>
  let answer5 = (
    <div className="answer">
      One activation is connected to one machine. You can remove an activation
      to activate it on a different machine anytime.{" "}
      <a href="#">Here, read here more about it.</a>
    </div>
  )

  ///Question 6
  let question6 = <div className="question">Where can i Download ASCALIA?</div>
  let answer6 = (
    <div className="answer">
      One activation is connected to one machine. You can remove an activation
      to activate it on a different machine anytime.
      <a href="#">Here, read here more about it.</a>
    </div>
  )

  ///Question 7
  let question7 = (
    <div className="question">How Do i Use FEATURE ... IN ASCALIA?</div>
  )
  let answer7 = (
    <div className="answer">
      One activation is connected to one machine. You can remove an activation
      to activate it on a different machine anytime.{" "}
      <a href="#">Here, read here more about it.</a>
    </div>
  )

  ///Question 8
  let question8 = <div className="question">Sales Support</div>
  let answer8 = (
    <div className="answer">
      One activation is connected to one machine. You can remove an activation
      to activate it on a different machine anytime.{" "}
      <a href="#">Here, read here more about it.</a>
    </div>
  )

  ///Question 9
  let question9 = <div className="question">Technical Support</div>
  let answer9 = (
    <div className="answer">
      One activation is connected to one machine. You can remove an activation
      to activate it on a different machine anytime.{" "}
      <a href="#">Here, read here more about it.</a>
    </div>
  )

  let choose_hook = ord_num => {
    switch (ord_num) {
      case 1:
        setOpen1(open => (open = !open))
        break
      case 2:
        setOpen2(open => (open = !open))
        break
      case 3:
        setOpen3(open => (open = !open))
        break
      case 4:
        setOpen4(open => (open = !open))
        break
      case 5:
        setOpen5(open => (open = !open))
        break
      case 6:
        setOpen6(open => (open = !open))
        break
      case 7:
        setOpen7(open => (open = !open))
        break
      case 8:
        setOpen8(open => (open = !open))
        break
      case 9:
        setOpen9(open => (open = !open))
        break
      default:
        break
    }
  }

  let check_hook_state = ord_num => {
    switch (ord_num) {
      case 1:
        return open1
      case 2:
        return open2
      case 3:
        return open3
      case 4:
        return open4
      case 5:
        return open5
      case 6:
        return open6
      case 7:
        return open7
      case 8:
        return open8
      case 9:
        return open9
      default:
        break
    }
  }

  let question_f = (question, answer, ord_num) => {
    return check_hook_state(ord_num) ? (
      <>
        <div className="question-box">
          <button
            onClick={() => {
              choose_hook(ord_num)
            }}
          >
            <Open className="arrow-open" />
            {question}
          </button>
        </div>
        {answer}
      </>
    ) : (
      <div className="question-box">
        <button onClick={() => choose_hook(ord_num)}>
          <Closed className="arrow-closed" />
          {question}
        </button>
      </div>
    )
  }

  return (
    <AnnualStyle>
      <div className="faq-holder">
        <h3>FAQ</h3>
        <div className="qa-holder">
          {question_f(question1, answer1, 1)}
          <hr></hr>
          {question_f(question2, answer2, 2)}
          <hr></hr>
          {question_f(question3, answer3, 3)}
          <hr></hr>
          {question_f(question4, answer4, 4)}
          <hr></hr>
          {question_f(question5, answer5, 5)}
          <hr></hr>
          {question_f(question6, answer6, 6)}
          <hr></hr>
          {question_f(question7, answer7, 7)}
          <hr></hr>
          {question_f(question8, answer8, 8)}
          <hr></hr>
          {question_f(question9, answer9, 9)}

          <div className="more-questions">
            Still have some questions? <a href="#">Email</a> us, or{" "}
            <a href="#">give us a call.</a>
            We are happy to help you!
          </div>
        </div>
      </div>
    </AnnualStyle>
  )
}

export default FAQMobile
