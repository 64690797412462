import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { useState, useEffect, useRef } from "react"
import Open from "../images/faq/open.svg"
import Closed from "../images/faq/closed.svg"

const AnnualStyle = styled.section`
  .main-box {
    width: 100vw;
    margin-left: 0;
    margin-right: 0;
  }

  /* .image-holder {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  } */

  @media (min-width: 1024px) {
    margin-top: 125px;
    .main-box {
      background: #e3f9e5;
      width: 1376px;
      border-radius: 8px;
      margin-bottom: 0px;
      border: 1px solid #e8e6e1;
    }

    .title-large {
      font-weight: bold;
      font-size: 48px;
      line-height: 56px;
      color: #423d33;
      margin: 32px 0px;
      text-align: center;
      text-transform: capitalize;
      padding-top: 78px;
      width: 100%;
    }

    .introduction {
      font-weight: normal;
      flex-grow: 0;
      margin-bottom: 64px;
      color: #423d33;
      width: 534px;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      font-feature-settings: "ss04" on, "ss03" on, "ss02" on, "ss06" on,
        "ss07" on;
    }

    .title-medium {
      font-weight: bold;
      font-size: 48px;
      text-align: center;
      text-transform: capitalize;
      color: #423d33;
      font-size: 40px;
      line-height: 48px;
      width: 532px;
      margin: 0px;
      margin-top: 32px;
      margin-bottom: 96px;
    }

    .title-small {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #423d33;
      margin: 25px 0px;
    }

    .button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      text-align: center;
      cursor: pointer;
    }

    .explanation {
      font-weight: normal;
      font-size: 18px;
      line-height: 24px;
      display: flex;
      align-items: start;
      text-align: start;
      font-feature-settings: "ss04" on, "ss03" on, "ss02" on, "ss06" on,
        "ss07" on;
      color: #625d52;
      padding-bottom: 23px;
      margin-left: 24px;
    }

    .img {
      height: auto;
      width: 100%;
      min-height: 362px;
      max-height: 432px;
      min-width: 480px;
      max-width: 575px;

      align-self: start;
    }

    .introduction-box {
      /* margin: 0px 24px; */
      width: 100vw;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    .holder {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: start;
      text-align: left;
      width: 37.5%;
      margin-right: 64px;
      margin-bottom: 128px;
      min-height: 473px;
    }

    .interactive-box {
      width: 100vw;
      display: flex;
      flex-direction: row;
      align-items: start;
      justify-content: center;
      text-align: center;
    }

    hr {
      background: #c1eac5 !important;
      width: 100%;
      border: 1px #c1eac5 solid;
      border-radius: 8px;
      opacity: 1 !important;
      margin: 0;
    }

    .arrow-closed {
      margin-right: 16px;
      margin-bottom: 28px;
    }
    .arrow-open {
      margin-right: 12px;
      margin-bottom: 28px;
    }

    .console {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      text-align: center;
      transform: translateY(-20px);
      overflow: visible;
      height: 46px;
    }

    .img-console {
      width: 743px;
      height: 220px;
      overflow: visible;
    }

    .image-holder {
      width: 46.87%;
      margin-top: 28px;
      min-height: 362px;
      max-height: 432px;
      min-width: 480px;
      max-width: 575px;
    }

    .closed {
      /* margin-bottom: 28px; */
      margin-right: 10px;
    }

    .open {
      /* margin-bottom: 28px; */
      margin-right: 10px;
    }
  }

  @media (min-width: 1376px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .interactive-box {
      width: 100%;
    }
    .introduction-box {
      width: 100%;
      margin-left: 0;
    }
  }

  @media (min-width: 1440px) {
    margin-top: 125px;
    .main-box {
      background: #e3f9e5;
      width: 1376px;
      border-radius: 8px;
      margin-bottom: 0px;
      border: 1px solid #e8e6e1;
    }

    .title-large {
      font-weight: bold;
      font-size: 48px;
      line-height: 56px;
      color: #423d33;
      margin: 32px 0px;
      text-align: center;
      text-transform: capitalize;
      padding-top: 78px;
      width: 100%;
    }

    .introduction {
      font-weight: normal;
      flex-grow: 0;
      margin-bottom: 64px;
      color: #423d33;
      width: 534px;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      font-feature-settings: "ss04" on, "ss03" on, "ss02" on, "ss06" on,
        "ss07" on;
    }

    .title-medium {
      font-weight: bold;
      font-size: 48px;
      text-align: center;
      text-transform: capitalize;
      color: #423d33;
      font-size: 40px;
      line-height: 48px;
      width: 532px;
      margin: 0px;
      margin-top: 32px;
      margin-bottom: 96px;
    }

    .title-small {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #423d33;
      margin: 25px 0px;
    }

    .button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    .explanation {
      font-weight: normal;
      font-size: 18px;
      line-height: 24px;
      display: flex;
      align-items: start;
      text-align: start;
      font-feature-settings: "ss04" on, "ss03" on, "ss02" on, "ss06" on,
        "ss07" on;
      color: #625d52;
      padding-bottom: 23px;
      margin-left: 24px;
    }

    .img {
      /* width: 20% !important;
      height: 20% !important; */
      align-self: start;
    }

    .introduction-box {
      margin: 0px 24px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    .holder {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: start;
      text-align: left;
      width: 480px;
      /* height: 433px; */
      margin-right: 64px;
      margin-bottom: 128px;
    }

    .interactive-box {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: start;
      justify-content: center;
      text-align: center;
    }

    hr {
      background: #c1eac5;
      width: 100%;
      border-top: 1px solid #c1eac5;
      opacity: 1 !important;
      margin: 0;
    }

    .arrow-closed {
      margin-right: 16px;
      margin-bottom: 28px;
    }
    .arrow-open {
      margin-right: 12px;
      margin-bottom: 28px;
    }

    .console {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      text-align: center;
      transform: translateY(-20px);
      overflow: visible;
      height: 46px;
    }

    .img-console {
      width: 743px;
      height: 220px;
      overflow: visible;
    }

    .image-holder {
      height: 100%;
      margin-top: 28px;
    }

    .closed {
      /* margin-bottom: 28px; */
      margin-right: 10px;
    }

    .open {
      /* margin-bottom: 28px; */
      margin-right: 10px;
    }
  }

  @media (max-width: 1023px) {
    display: none;
  }

  /* .hide-img {
    display: none;
  } */

  .img {
    /* position: absolute;
    left: 50%; */
    width: 100%;
  }
`

const SinglePlatformOther = () => {
  const [open, setOpen] = useState(() => 1)

  let checkingOpen = ord_num => {
    if (ord_num !== open) {
      return <Closed className="closed" />
    } else {
      return <Open className="open" />
    }
  }

  let showExplanation = ord_num => {
    if (ord_num === 1 && ord_num === open) {
      return (
        <div className="explanation">
          Real-Time analysis of production efficiency with live alerts and
          optimisation tips
        </div>
      )
    }

    if (ord_num === 2 && ord_num === open) {
      return (
        <div className="explanation">
          Analysing product quality in all the key place on the line, reducing
          rework and defects
        </div>
      )
    }

    if (ord_num === 3 && ord_num === open) {
      return (
        <div className="explanation">
          Reduce production downtime and maintenance costs with properly timed
          maintenance
        </div>
      )
    }

    if (ord_num === 4 && ord_num === open) {
      return (
        <div className="explanation">
          Innovative traceability solutions tailored for manufacturing industry
          and batch manufacturing
        </div>
      )
    }

    if (ord_num === 5 && ord_num === open) {
      return (
        <div className="explanation">
          Organise your workforce and increase efficiency with transparent work
          organisation
        </div>
      )
    }
  }

  const img = useRef([])
  const button = useRef([])

  useEffect(() => {
    img.current.children[0].style.display = `flex`
    img.current.children[1].style.display = `none`
    img.current.children[2].style.display = `none`
    img.current.children[3].style.display = `none`
    img.current.children[4].style.display = `none`
  }, [])

  let choosingImage = ord_num => {
    console.log("function works")
    for (let i = 0; i < 5; i++) {
      if (i == ord_num - 1) {
        img.current.children[i].style.display = `flex`
        console.log("flex")
      } else {
        img.current.children[i].style.display = `none`
        console.log("none")
      }
    }
  }
  return (
    <AnnualStyle>
      <div className="main-box">
        <div className="introduction-box">
          <div className="console">
            <StaticImage
              className="img-console"
              layout="constrained"
              placeholder="blurred"
              src="../images/hero/console_other.png"
              alt="era of manufacturing"
              loading="eager"
              quality={100}
            />
          </div>
          <div className="title-large">
            One solution for your
            <br />
            entire shop floor
          </div>
          <div className="introduction">
            Ascalia gives you a set of tailored tools to stay in control of your
            production.
          </div>
          {/* <div className="title-medium">
            What we enable is the new era of manufacturing:
          </div> */}
        </div>
        <div className="interactive-box">
          <div className="holder">
            <div
              className="button"
              id="button-1"
              ref={el => (button.current[0] = el)}
              onClick={() => {
                setOpen(1)
                choosingImage(1)
                console.log("test")
              }}
            >
              {checkingOpen(1)}
              <div className="title-small">
                Efficiency Analysis & Optimisation
              </div>
            </div>
            {showExplanation(1)}
            <hr />
            <div
              className="button"
              id="button-2"
              onClick={() => {
                setOpen(2)
                choosingImage(2)
              }}
            >
              {checkingOpen(2)}
              <div className="title-small">Ai Powered QA</div>
            </div>
            {showExplanation(2)}
            <hr />
            <div
              className="button"
              id="button-3"
              onClick={() => {
                setOpen(3)
                choosingImage(3)
              }}
            >
              {checkingOpen(3)}
              <div className="title-small">Predictive maintenace</div>
            </div>
            {showExplanation(3)}
            <hr />
            <div
              className="button"
              id="button-4"
              onClick={() => {
                setOpen(4)
                choosingImage(4)
              }}
            >
              {checkingOpen(4)}
              <div className="title-small">Production Tracking</div>
            </div>
            {showExplanation(4)}
            <hr />
            <div
              className="button"
              id="button-5"
              onClick={() => {
                setOpen(5)
                choosingImage(5)
              }}
            >
              {checkingOpen(5)}
              <div className="title-small">Work Attendance & Planning</div>
            </div>
            {showExplanation(5)}
          </div>
          <div className="image-holder" ref={img}>
            <StaticImage
              className="img img1-id"
              layout="constrained"
              id="img1"
              quality={100}
              placeholder="blurred"
              src="../images/era_of_manufacturing_ilustrations/efficiency_other.png"
              alt="era of manufacturing"
              loading="eager"
            />

            <StaticImage
              className="img hide-img img2-id"
              layout="constrained"
              id="img2"
              quality={100}
              src="../images/era_of_manufacturing_ilustrations/ai_quality_other.png"
              alt="era of manufacturing"
              loading="eager"
            />

            <StaticImage
              className="img hide-img img3-id"
              layout="constrained"
              id="img3"
              placeholder="blurred"
              src="../images/era_of_manufacturing_ilustrations/predictive_maintenance_other.png"
              alt="era of manufacturing"
              quality={100}
              loading="eager"
            />
            <StaticImage
              className="img hide-img img4-id"
              layout="constrained"
              id="img4"
              quality={100}
              placeholder="blurred"
              src="../images/era_of_manufacturing_ilustrations/tracking_other.png"
              alt="era of manufacturing"
              loading="eager"
            />

            <StaticImage
              className="img hide-img img5-id"
              layout="constrained"
              id="img5"
              quality={100}
              placeholder="blurred"
              src="../images/era_of_manufacturing_ilustrations/work_planning_other.png"
              alt="era of manufacturing"
              loading="eager"
            />
          </div>
        </div>
      </div>
    </AnnualStyle>
  )
}

export default SinglePlatformOther
