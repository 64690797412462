import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"

import { HeroPopUpContext } from "../../contexts/contextHero"
import { useContext } from "react"

const Style = styled.div`
  z-index: 500;
  .box-pos {
    position: absolute;
  }

  .circle {
    position: absolute;
    width: 50px;
    height: 50px;
    border: 1px solid rgba(80, 74, 64, 0.34);
    border-radius: 100%;
    background-color: none;
    z-index: -1;
    transition: width 500ms, height 500ms, transform 1000ms;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(1);
  }

  .circle-shadow {
    position: absolute;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -1;
    background: rgba(80, 74, 64, 0.34);
    transition: background-color 1000ms;
  }

  .circle-white {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    z-index: 1;
    background: #fcfdfc;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.55);
    opacity: 1 !important;
  }

  .circle-collision {
    position: absolute;
    z-index: 100;
    width: 52px;
    height: 52px;
  }

  .circle-collision:hover {
    .circle {
      transition: width 1000ms, height 1000ms;
      width: 1px;
      height: 1px;
    }

    .circle-shadow {
      transition: background-color 1000ms;
      background-color: #2f8132;
    }
  }

  @media (hover: none) and (pointer: coarse) {
    .circle-collision:hover {
      .circle {
        transition: width 1000ms, height 1000ms;
        width: 1px;
        height: 1px;
      }
    }
  }

  .circle:active {
    transition: width 1000ms, height 1000ms, transform 1000ms;
    width: 1px;
    height: 1px;
    animation: none;
  }

  .circle-shadow {
    position: absolute;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    background: rgba(80, 74, 64, 0.34);
    transition: background-color 1000ms;
  }

  .circle-white {
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    z-index: 1;
    background: #fcfdfc;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.55);
    opacity: 1 !important;
  }

  /* .circle1 {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  } */

  .card-title {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-transform: capitalize;
    color: #423d33;
    text-align: left;
  }

  .card-text {
    font-size: 14px;
    line-height: 24px;
    color: #625d52;
    text-align: left;
  }

  .hero-box {
    transition: opacity 1000ms;
    position: absolute;
    width: 210px;
    height: auto;
    background: linear-gradient(
      180deg,
      rgba(227, 249, 229, 0.89) 0%,
      rgba(250, 249, 247, 0.55) 54.69%
    );
    backdrop-filter: blur(4px);
    border-radius: 8px;
    padding: 24px;
    opacity: 0;
    transition: opacity 1000ms;
    z-index: 100;
  }

  .circle1 {
    transition: transform 2000ms;
  }

  .pulsate {
    transition: transform 1200ms ease-in 0s;
    transform: scale(1.3);
  }

  .active {
    opacity: 1;
    .circle {
      transition: width 1000ms, height 1000ms, transform 1000ms;
      width: 1px;
      height: 1px;
      animation: none;
    }
    .circle-shadow {
      transition: background-color 1000ms;
      background: rgba(80, 74, 64, 1);
    }
  }

  @media (max-width: 767px) {
    .card-text {
      font-size: 12px;
      line-height: 20px;
    }

    .card-title {
      font-size: 14px;
      line-height: 16px;
    }

    .hero-box {
      height: auto;
    }

    .box1 {
      width: 162px;
    }

    @media (max-width: 374px) {
      .card-title {
        font-size: 10px;
        line-height: 14px;
        text-align: center;
      }

      .card-text {
        font-size: 10px;
        line-height: 14px;
        text-align: center;
      }

      .hero-box {
        width: 128px;
        height: auto;
        padding: 16px;
      }
    }
  }
`

function PopUpCard(props) {
  const circle = useRef(null)
  const circleShadow = useRef(null)
  const interval = useRef(null)
  const box = useRef(null)
  const [active, setActive] = useState(false)
  const [boxDepth, setBoxDepth] = useState(() => "2")
  const [boxCenter, setBoxCenter] = useState(() => parseInt(props.box_x) + "px")

  const { popActive } = useContext(HeroPopUpContext)
  const { setPopActive } = useContext(HeroPopUpContext)

  useEffect(() => {
    // Setting up or removing state based on hook pick
    if (props.id == popActive) {
      box.current.classList.add("active")
      circle.current.classList.add("active")
      setActive(a => true)
      setBoxDepth(() => "5")
      if (circle.current.classList.contains("pulsate"))
        circle.current.classList.remove("pulsate")
    } else {
      box.current.classList.remove("active")
      circle.current.classList.remove("active")
      setBoxDepth(() => "2")
    }

    // Box centering when size changes
    parseInt(window.innerWidth) < 375
      ? setBoxCenter(
          () =>
            parseInt(props.box_x) +
            (258 - parseInt(box.current.offsetWidth)) +
            "px"
        )
      : setBoxCenter(() => parseInt(props.box_x) + 60 + "px")

    // Box centering when size changes
    window.addEventListener("resize", () => {
      parseInt(window.innerWidth) < 375
        ? setBoxCenter(
            () =>
              parseInt(props.box_x) +
              (258 - parseInt(box.current.offsetWidth)) +
              "px"
          )
        : setBoxCenter(() => parseInt(props.box_x) + 60 + "px")
    })

    // Pulsing
    setTimeout(() => {
      if (circle.current.classList.contains("pulsate"))
        circle.current.classList.remove("pulsate")
      else if (!circle.current.classList.contains("active"))
        circle.current.classList.add("pulsate")

      interval.current = setInterval(() => {
        if (circle != undefined) {
          if (circle.current.classList.contains("pulsate"))
            circle.current.classList.remove("pulsate")
          else if (!circle.current.classList.contains("active"))
            circle.current.classList.add("pulsate")
        }
      }, 1700 + 300 * Math.random())
    }, Math.random() * 2000)

    return () => clearInterval(interval.current)
  }, [popActive])

  const changeActive = () => {
    setPopActive(a => props.id)

    box.current.classList.add("active")

    circle.current.classList.add("active")

    setBoxDepth(() => "5")

    if (circle.current.classList.contains("pulsate"))
      circle.current.classList.remove("pulsate")
  }

  return (
    <Style>
      <div
        style={{ top: props.circle_y, left: props.circle_x }}
        className="circle1 circle-collision"
        ref={circle}
        onClick={() => changeActive()}
      >
        <div className="circle ">
          <div className="circle-shadow">
            <div className="circle-white"></div>
          </div>
        </div>
      </div>

      <div
        className="box-pos"
        style={{
          top: props.circle_y,
          left: props.circle_x,
        }}
      >
        <div
          ref={box}
          style={{
            top: props.box_y,
            left: boxCenter,
            zIndex: boxDepth,
          }}
          className="hero-box box1"
        >
          <div className="card-title">{props.title}</div>
          <div className="card-text">{props.description}</div>
        </div>
      </div>
    </Style>
  )
}

PopUpCard.defaultProps = {
  circle_x: "0px",
  circle_y: "0px",
  box_x: "0px",
  box_y: "0px",
  title: "Title",
  description: "Description here",
}

export default PopUpCard
