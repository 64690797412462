import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

const AnnualStyle = styled.section`
  background: #faf9f7;
  padding-top: 40px;
  padding-bottom: 20px;
  .logo-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #b8b2a7;
    margin: 24px;
  }

  .image {
    width: 48px;
    height: 48px;
    margin-top: 24px;
  }

  .product-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 187px;
  }

  .company-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 123px;
  }

  .element {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    margin: 16px 0px;
  }

  .mini-title {
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
  }
  .copyright {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    margin: 16px 0px;
  }

  .holder {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    text-align: center;
    flex-wrap: wrap-reverse;
  }

  ///izbaci
  .trademark {
    max-width: 224px;
    max-width: 100%;
  }

  @media (min-width: 768px) {
    .holder {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
      text-align: center;
      flex-wrap: nowrap;
    }

    .logo-block {
      width: 224px;
      width: 100%;
      align-items: flex-start;
      text-align: left;
      margin-left: 40px;
      margin-right: 40px;
    }

    .product-block {
      width: 215px;
      align-items: flex-start;
      text-align: left;
      margin-top: 40px;
      margin-left: 40px;
      margin-right: 40px;
    }
    .company-block {
      width: 69px;
      align-items: flex-start;
      text-align: left;
      margin-top: 40px;
      margin-left: 40px;
      margin-right: 40px;
    }
    .element {
      margin: 12px 0px;
    }
    .product-block {
      margin-bottom: 40px;
    }
    .logo-block {
      margin-bottom: 40px;
    }
    .company-block {
      margin-bottom: 40px;
    }

    //izbacy
    .logo-block {
      align-items: center;
      text-align: center;
    }
  }

  @media (min-width: 1440px) {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;

    .holder {
      width: 1440px;
      justify-content: space-evenly;
    }
  }

  .hide {
    display: none;
  }
`

const AboutUs = () => {
  return (
    <AnnualStyle>
      <div className="holder">
        <div className="logo-block">
          <div className="image">
            <StaticImage
              className="unedited-image"
              src="../images/ascalia_logo.png"
              layout="constrained"
            />
          </div>
          <div className="copyright">
            Copyright 2021 <br />
            {/* All Rights Reserved */}
          </div>
          <div className="trademark">
            All Rights Reserved. Ascalia and Ascalia logo are registered
            trademark of Ascalia ltd. in United Kingdom and other Countries.
          </div>
        </div>
        <div className="product-block hide">
          <div className="mini-title element">Product</div>
          <div className="element">Predictive Maintenance</div>
          <div className="element">Ai-power Quality Control</div>
          <div className="element">Efficiency Analysis & Optimization</div>
          <div className="element">3P Production Tracking™</div>
          <div className="element">Work Attendace & Planning</div>
        </div>
        <div className="company-block hide">
          <div className="mini-title element">Company</div>
          <div className="element">About</div>
          <div className="element">Blog</div>
          <div className="element">Carrers</div>
          <div className="element">Contact</div>
          <div className="element">Legal</div>
        </div>
      </div>
    </AnnualStyle>
  )
}

export default AboutUs
