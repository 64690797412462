import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { useState, useEffect } from "react"
import Prev from "../images/how_does_it_work/left.svg"
import Next from "../images/how_does_it_work/right.svg"

const AnnualStyle = styled.section`
  .review-box {
    width: 248px;
    height: 338.67px;
    background: #fcfdfc;
    border: 1px solid #e8e6e1;
    box-sizing: border-box;
    box-shadow: 0px 24px 32px rgba(66, 61, 51, 0.13);
    border-radius: 4px;
    flex-shrink: 0;
    margin: 40px 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .finish {
    width: 1px;
    flex-shrink: 0;
    height: 338.67px;
    background: #fcfdfc;
  }

  .carousel-costume {
    overflow-x: scroll;
    overflow-y: visible;
    width: 100vw;
  }

  .carousel-content {
    overflow: visible;
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    margin: auto;
    padding-right: 24px;
  }

  /////default scroll bar invisible
  /* width */
  .carousel-costume::-webkit-scrollbar {
    height: 0px;
    display: none;
  }

  /* Track */
  .carousel-costume::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  .carousel-costume::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  .carousel-costume::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .scroll-bar {
    margin-top: 20px;
    height: 2px;
    background: #d3cec4;
    margin: 20px 24px;
    border-radius: 8px;
  }

  .thumb {
    position: absolute;
    height: 2px;
    background: #423d33;
    width: 100px;
    cursor: pointer;
    border-radius: 8px;
  }

  .scroll-bar:hover {
    height: 4px;
    .thumb {
      height: 4px;
    }
  }
  .scroll-bar:active {
    height: 4px;
  }

  .thumb:active {
    height: 6px !important;
  }

  .title {
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    text-transform: capitalize;
    color: #423d33;
    font-weight: bold;
    margin-top: 64px;
  }

  .img {
    width: 192px;
    height: 64px;
    /* mix-blend-mode: multiply; */
    margin-top: 16px;
  }

  p {
    padding-top: 24px;
    margin: 0px 16px;
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    font-feature-settings: "ss04" on, "ss03" on, "ss02" on, "ss06" on, "ss07" on;
    color: #625d52;
  }

  .signature {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    font-feature-settings: "ss04" on, "ss03" on, "ss02" on, "ss06" on, "ss07" on;
    color: #423d33;
    padding-top: 24px;
  }

  .ceo {
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    margin-top: 12px;
    color: #b8b2a7;
  }

  @media (min-width: 768px) {
    .review-box {
      width: 324px;
      height: 402px;
    }

    .scroll-bar {
      margin-right: 48px;
      margin-left: 48px;
    }

    .signature {
      font-size: 24px;
      line-height: 32px;
      padding-top: 33px;
    }
    p {
      padding-top: 33px;
    }

    .img {
      width: 240px;
      height: 80px;
    }

    .ceo {
      font-size: 18px;
      line-height: 24px;
    }

    .title {
      font-size: 40px;
      line-height: 48px;
    }
  }

  .desktop {
    display: none;
  }

  @media (min-width: 1024px) {
    .desktop {
      display: block;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .complete-carousel {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    .carousel-costume {
      width: 100vw;
      overflow-y: visible;
    }

    .scroll-bar {
      width: 720px;
    }

    .review-box {
      width: 336px;
      height: 400px;
    }

    .img {
      width: 272px;
      height: 96px;
    }
    .scroll-bar {
      margin-right: 0;
      margin-left: 0;
    }

    .prevArrow,
    .nextArrow {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 200px;
      height: 500px;
      position: absolute;
      z-index: 1;
    }

    .prevArrow {
      left: 0px;
      background: linear-gradient(
        90deg,
        rgba(252, 253, 252, 1),
        rgba(252, 253, 252, 0.9),
        rgba(252, 253, 252, 0.1)
      );
    }
    .nextArrow {
      left: 100vw;
      transform: translateX(-200px);
      background: linear-gradient(
        -90deg,
        rgba(252, 253, 252, 1),
        rgba(252, 253, 252, 0.9),
        rgba(252, 253, 252, 0.1)
      );
    }
  }

  @media (min-width: 1440px) {
    .review-box {
      width: 352px;
      height: 486px;
    }

    .carousel-costume {
      /* width: 1160px; */
      overflow-y: visible;
    }

    .scroll-bar {
      width: 1160px;
    }

    .title {
      font-size: 48px;
      line-height: 56px;
    }

    .prevArrow,
    .nextArrow {
      height: 586px;
    }
  }
`

const WhatDoCostumerSayNew = () => {
  const [scrollBarX, setScrollBarX] = useState(() => 0)

  useEffect(() => {
    let grow_height = "6px"
    let carousel = document.querySelector(".carousel-costume")
    let thumb = document.querySelector(".thumb")
    let scrollBar = document.querySelector(".scroll-bar")
    let prev = document.querySelector(".prevArrow")
    let next = document.querySelector(".nextArrow")

    /// PRIVREMENA PRILAGODBA
    thumb.style.width = scrollBar.offsetWidth / 5 + "px"

    const arrowMovement = direction => {
      let c_scroll_width =
        document.querySelector(".carousel-costume").scrollWidth
      let c_width = document.querySelector(".carousel-costume").offsetWidth
      let movement_factor = c_scroll_width - c_width
      let holder_width = document.querySelector(".review-box").offsetWidth + 48

      function SmoothHorizontalScrolling(e, time, amount, start) {
        var eAmt = amount / 100
        var curTime = 0
        var scrollCounter = 0
        while (curTime <= time) {
          window.setTimeout(SHS_B, curTime, e, scrollCounter, eAmt, start)
          curTime += time / 100
          scrollCounter++
        }
      }

      SmoothHorizontalScrolling(
        carousel,
        275,
        holder_width * direction,
        carousel.scrollLeft
      )

      function SHS_B(e, sc, eAmt, start) {
        e.scrollLeft = eAmt * sc + start
      }

      thumb.style.left =
        (carousel.scrollLeft / movement_factor) *
          (scrollBar.offsetWidth - thumb.offsetWidth) +
        scrollBar.offsetLeft +
        "px"
    }

    prev.onmousedown = () => {
      arrowMovement(-1)
    }

    next.onmousedown = () => {
      arrowMovement(1)
    }

    thumb.style.left = scrollBar.offsetLeft + "px"

    ////////////// PC
    thumb.onmousedown = () => {
      let clicked_on_thumb = true

      // modular thumb centering
      let thumb_height_centar =
        scrollBar.offsetTop -
        (thumb.offsetHeight - scrollBar.offsetHeight) / 2 +
        "px"
      thumb.style.top = thumb_height_centar
      document.onmouseup = () => {
        if (clicked_on_thumb) {
          let scrollBar_Y_position = scrollBar.offsetTop + "px"
          thumb.style.top = scrollBar_Y_position
          clicked_on_thumb = false
        }
      }

      let c_scroll_width =
        document.querySelector(".carousel-costume").scrollWidth
      let c_width = document.querySelector(".carousel-costume").offsetWidth
      /// movement factor is equal to difference of real scroll width and visual scroll width
      let movement_factor = c_scroll_width - c_width

      document.onmousemove = mm => {
        if (clicked_on_thumb) {
          let thumb_width = thumb.offsetWidth
          /// go to position corrected with thumb centering
          let go_to = mm.x - thumb_width / 2
          if (go_to < scrollBar.offsetLeft) go_to = scrollBar.offsetLeft
          /// checking if go to position is out of scroll bar
          if (
            go_to >
            scrollBar.offsetLeft + scrollBar.offsetWidth - thumb_width
          )
            go_to = scrollBar.offsetLeft + scrollBar.offsetWidth - thumb_width
          /// scrolling function
          carousel.scrollLeft =
            movement_factor *
            (Math.abs(scrollBar.offsetLeft - go_to) /
              (scrollBar.offsetWidth - thumb_width))

          /// update position of visual scroll bar
          thumb.style.left = go_to + "px"
        }
      }
    }

    ////////////////////////////// MOBILE
    thumb.addEventListener("touchstart", () => {
      let clicked_on_thumb = true
      thumb.style.height = grow_height
      scrollBar.style.height = "4px"

      // modular thumb centering
      let thumb_height_centar =
        scrollBar.offsetTop -
        (thumb.offsetHeight - scrollBar.offsetHeight) / 2 +
        "px"
      thumb.style.top = thumb_height_centar

      document.addEventListener("touchend", () => {
        if (clicked_on_thumb) {
          thumb.style.top = scrollBar.offsetTop + "px"
          thumb.style.height = ""
          scrollBar.style.height = ""
          clicked_on_thumb = false
        }
      })

      /// movement factor is equal to difference of real scroll width and visual scroll width
      let c_scroll_width =
        document.querySelector(".carousel-costume").scrollWidth
      let c_width = document.querySelector(".carousel-costume").offsetWidth
      let movement_factor = c_scroll_width - c_width

      document.addEventListener("touchmove", mm => {
        if (clicked_on_thumb) {
          let thumb_width = thumb.offsetWidth
          /// go to position corrected with thumb centering
          let go_to = mm.touches[0].clientX - thumb_width / 2
          if (go_to < scrollBar.offsetLeft) go_to = scrollBar.offsetLeft

          /// checking if go to position is out of scroll bar
          if (
            go_to >
            scrollBar.offsetLeft + scrollBar.offsetWidth - thumb_width
          )
            go_to = scrollBar.offsetLeft + scrollBar.offsetWidth - thumb_width
          /// scrolling function
          carousel.scrollLeft =
            movement_factor *
            (Math.abs(scrollBar.offsetLeft - go_to) /
              (scrollBar.offsetWidth - thumb_width))
          /// update position of visual scroll bar
          thumb.style.left = go_to + "px"
        }
      })
    })
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const adabtToDOMchange = DOMchangeType => {
      if (DOMchangeType !== "scrollCarousel") {
        thumb.style.top =
          scrollBar.offsetTop -
          (thumb.offsetHeight - scrollBar.offsetHeight) / 2 +
          "px"
      }

      let c_scroll_width =
        document.querySelector(".carousel-costume").scrollWidth
      let c_width = document.querySelector(".carousel-costume").offsetWidth
      let movement_factor = c_scroll_width - c_width

      if (DOMchangeType === "resize")
        thumb.style.width = movement_factor / 5 + "px"

      thumb.style.left =
        (carousel.scrollLeft / movement_factor) *
          (scrollBar.offsetWidth - thumb.offsetWidth) +
        scrollBar.offsetLeft +
        "px"
    }

    window.addEventListener("resize", () => {
      adabtToDOMchange("resize")
    })

    window.addEventListener("scroll", () => {
      adabtToDOMchange("scroll")
    })

    carousel.addEventListener("scroll", () => {
      adabtToDOMchange("scrollCarousel")

      setScrollBarX(i => i)
    })
  }, [])

  return (
    <AnnualStyle>
      <div className="title">Who we helped</div>
      <div className="complete-carousel">
        <div className="prevArrow desktop">
          <Prev></Prev>
        </div>
        <div className="carousel-costume">
          <div className="carousel-content">
            <div className="review-box">
              <StaticImage
                tag="section"
                className="img podravka"
                quality={100}
                layout="constrained"
                placeholder="blurred"
                src="../images/wdcs_desktop_mobile_tablet/logos/podravka.png"
                alt="Podravka logo"
                loading="eager"
              ></StaticImage>
              <p>
                “Switzerland is small and neutral! We are more like Germany,
                ambitious and misunderstood!”
              </p>
              <p className="signature">Werko Plywoodich</p>
              <div className="ceo">CEO</div>
            </div>
            <div className="review-box">
              <StaticImage
                tag="section"
                className="img ina"
                quality={100}
                layout="constrained"
                placeholder="blurred"
                src="../images/wdcs_desktop_mobile_tablet/logos/ina.png"
                alt="INA logo"
                loading="eager"
              ></StaticImage>
              <p>
                “Switzerland is small and neutral! We are more like Germany,
                ambitious and misunderstood!”
              </p>
              <p className="signature">Werko Plywoodich</p>
              <div className="ceo">CEO</div>
            </div>
            <div className="review-box">
              <StaticImage
                tag="section"
                className="img iverpan"
                quality={100}
                layout="constrained"
                placeholder="blurred"
                src="../images/wdcs_desktop_mobile_tablet/logos/iverpan.png"
                alt="Iverpan logo"
                loading="eager"
              ></StaticImage>
              <p>
                “Switzerland is small and neutral! We are more like Germany,
                ambitious and misunderstood!”
              </p>
              <p className="signature">Werko Plywoodich</p>
              <div className="ceo">CEO</div>
            </div>
            <div className="review-box">
              {" "}
              <StaticImage
                tag="section"
                className="img podravka"
                quality={100}
                layout="constrained"
                placeholder="blurred"
                src="../images/wdcs_desktop_mobile_tablet/logos/podravka.png"
                alt="Podravka logo"
              ></StaticImage>
              <p>
                “Switzerland is small and neutral! We are more like Germany,
                ambitious and misunderstood!”
              </p>
              <p className="signature">Werko Plywoodich</p>
              <div className="ceo">CEO</div>
            </div>
            <div className="review-box">
              <StaticImage
                tag="section"
                className="img sainsburys"
                quality={100}
                layout="constrained"
                placeholder="blurred"
                src="../images/wdcs_desktop_mobile_tablet/logos/sainsburys.png"
                alt="Sainsbury logo"
                loading="eager"
              ></StaticImage>
              <p>
                “Switzerland is small and neutral! We are more like Germany,
                ambitious and misunderstood!”
              </p>
              <p className="signature">Werko Plywoodich</p>
              <div className="ceo">CEO</div>
            </div>
            <div className="finish"> </div>
          </div>
        </div>
        <div className="nextArrow desktop">
          <Next></Next>
        </div>
      </div>
      <div className="scroll-bar">
        <div className="thumb"></div>
      </div>
    </AnnualStyle>
  )
}

export default WhatDoCostumerSayNew
