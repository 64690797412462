import * as React from "react"
import styled from "styled-components"
import NvidaMobile from "../images/our_partners/nvida_mobile.svg"
import CoralMobile from "../images/our_partners/coral_mobile.svg"
import CoralOther from "../images/our_partners/coral_other.svg"
import NvidaOther from "../images/our_partners/nvida_other.svg"
import Coral1024 from "../images/our_partners/coral_1024.svg"
import Nvida1024 from "../images/our_partners/nvida_1024.svg"

const AnnualStyle = styled.section`
  font-style: normal;

  .arrow {
    margin-left: 18px;
  }

  .white-box {
    background-color: #faf9f7;
    margin: 24px 24px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 24px;
    border: 1px solid #e8e6e1;
    box-sizing: border-box;
    border-radius: 8px;
    padding-bottom: 0px;
  }

  .logo-mobile {
    margin-bottom: 24px;
  }

  .main-container {
    margin-top: 70px;
    display: inline;
  }

  .title {
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    text-align: center;

    color: #423d33;
    margin-top: 64px;
    margin-bottom: 40px;
  }

  .coral-image {
    width: 295px;
    overflow: visible;
    margin-bottom: 16px;
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #423d33;
    font-feature-settings: "ss04" on, "ss03" on, "ss02" on, "ss06" on, "ss07" on;
  }

  .learn-more {
    color: #faf9f7;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin: 24px 0px;
  }

  .learn-more-txt {
    background-color: #faf9f7;
    border: 1px solid #423d33;
    box-sizing: border-box;
    border-radius: 4px;
    width: 148px;
    height: 46px;
    padding: 16px 0px;
    font-weight: bold;
    font-size: 16px;
    line-height: 14px;
  }

  .logo-mobile {
    margin-top: 32px;
  }

  @media (max-width: 767px) {
    .logo-other {
      display: none;
    }
  }

  @media (min-width: 768px) {
    .logo-mobile {
      display: none;
    }

    .logo-other {
      margin-top: 24px;
      margin-bottom: 48px;
    }

    .learn-more-txt {
      margin-bottom: 32px;
    }

    .coral-image {
      width: 576px;
    }

    .white-box {
      margin-left: 48px;
      margin-right: 48px;
    }

    p {
      font-weight: normal;
      font-size: 24px;
      line-height: 32px;
      width: 576px;
    }

    .title {
      font-size: 32px;
      line-height: 40px;
      width: 100%;
      margin-top: 96px;
    }
  }
  @media (max-width: 1023px) {
    .logo-1024 {
      display: none;
    }
  }

  @media (min-width: 1024px) {
    @media (max-width: 1439px) {
      .logo-other {
        display: none;
      }
    }

    .logo-1024 {
      margin-top: 48px;
    }

    .white-box {
      margin-left: 0;
      margin-right: 0;
      width: 49%;
    }

    p {
      margin-top: 48px;
      width: 100%;
    }

    .card-container {
      /* width: 1376px; */
      display: flex;
      flex-direction: row;
      align-items: center;
      align-content: center;
      justify-content: space-between;
      flex-wrap: nowrap;
      padding-right: 48px;
      padding-left: 48px;
    }
  }

  @media (min-width: 1440px) {
    .logo-1024 {
      display: none;
    }

    .logo-other {
      margin: 0;
      margin-top: 48px;
    }

    .white-box {
      width: 672px;
      height: 360px;
      height: 100%;
      margin: 0px;
    }

    .title {
      margin-top: 96px;
      margin-bottom: 64px;
    }

    .card-container {
      width: 1376px;
      padding: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      align-content: center;
      justify-content: space-between;
      flex-wrap: nowrap;
    }
  }
  .hide {
    display: none;
  }
`

const OurPartnership = () => {
  return (
    <AnnualStyle>
      <div className="main-container">
        <div className="title">Our Partners</div>
        <div className="card-container">
          <div className="white-box">
            <CoralMobile className="logo-mobile" />
            <CoralOther className="logo-other" />
            <Coral1024 className="logo-1024" />
            <p>Coral from Google powers Ascalia edge devices.</p>

            <div className="learn-more">
              <button className="learn-more-txt hide">Read More</button>
            </div>
          </div>
          <div className="white-box">
            <NvidaMobile className="logo-mobile" />
            <NvidaOther className="logo-other" />
            <Nvida1024 className="logo-1024" />

            <p>Ascalia is member of NVIDIA Inception program.</p>

            <div className="learn-more">
              <button className="learn-more-txt hide">Read More</button>
            </div>
          </div>
        </div>
      </div>
    </AnnualStyle>
  )
}

export default OurPartnership
