import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

import { useState, useEffect, useRef } from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Prev from "../images/how_does_it_work/left.svg"
import Next from "../images/how_does_it_work/right.svg"
import PrevSmall from "../images/how_does_it_work/leftSmall.svg"
import NextSmall from "../images/how_does_it_work/rightSmall.svg"

const AnnualStyle = styled.section`
  border-top: 1px solid #e8e6e1;
  margin-top: 48px;

  .holder {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-shrink: 0;
  }

  .finish {
    width: 1px;
    flex-shrink: 0;
    height: 338.67px;
    background: #fcfdfc;
  }

  .carousel-costume {
    overflow-x: scroll;
    overflow-y: visible;
    width: 100vw;
    margin-bottom: 24px;
  }

  .carousel-content {
    overflow: visible;
    width: 100vw;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    margin: auto;
  }

  .complete-carousel {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  /////default scroll bar invisible
  /* width */
  .carousel-costume::-webkit-scrollbar {
    height: 0px;
    display: none;
  }

  /* Track */
  .carousel-costume::-webkit-scrollbar-track {
    background: none;
  }

  /* Handle */
  .carousel-costume::-webkit-scrollbar-thumb {
    background: none;
  }

  /* Handle on hover */
  .carousel-costume::-webkit-scrollbar-thumb:hover {
    background: none;
  }

  .scroll-bar-background {
    width: 100%;
    height: 40px;
    background: red;
    border-bottom: 1px solid #e8e6e1;
    background: #e3f9e5;
  }

  .scroll-bar {
    margin-top: 80px;
    margin: auto;
    height: 2px;
    width: 89%;
    background: #d3cec4;
    border-radius: 8px;
    margin-top: 0;
  }

  @media (max-width: 767px) {
    .tablet {
      display: none;
    }

    .scroll-bar {
      margin-left: 24px;
      margin-right: 24px;
      width: auto;
    }
  }

  .thumb {
    position: absolute;
    height: 2px;
    background: #423d33;
    /* width: 100px; */
    cursor: pointer;
    border-radius: 8px;
  }

  .scroll-bar:hover {
    height: 4px;
    .thumb {
      height: 4px;
    }
  }
  .scroll-bar:active {
    height: 4px;
  }

  .thumb:active {
    height: 6px !important;
  }

  .title {
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    text-transform: capitalize;
    color: #423d33;
    font-weight: bold;
    margin-top: 64px;
  }

  p {
    padding-top: 24px;
    margin: 0px 16px;
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    font-feature-settings: "ss04" on, "ss03" on, "ss02" on, "ss06" on, "ss07" on;
    color: #625d52;
  }

  .signature {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    font-feature-settings: "ss04" on, "ss03" on, "ss02" on, "ss06" on, "ss07" on;
    color: #423d33;
    padding-top: 24px;
  }

  .ceo {
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    margin-top: 12px;
    color: #b8b2a7;
  }

  .prevArrow,
  .nextArrow {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 48px;
    transition: height 1000ms;
    position: absolute;
    z-index: 5;
    cursor: pointer;
  }

  .prevArrow {
    left: 0px;
  }

  .nextArrow {
    left: 100vw;
    transform: translateX(-48px);
  }

  //////////////////////////////////margin
  /////////////////////////////////////margin
  ///////////////////////////////////margin

  .main-box {
    background: #e3f9e5;
  }

  .title-large {
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    color: #423d33;
    margin: 32px 0px;
    text-align: center;
    text-transform: capitalize;
  }

  .introduction {
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    font-feature-settings: "ss04" on, "ss03" on, "ss02" on, "ss06" on, "ss07" on;
    flex-grow: 0;
    margin-bottom: 32px;
    color: #423d33;
  }

  .title-medium {
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    text-transform: capitalize;
    color: #423d33;
    order: 1;
    flex-grow: 0;
    margin: 64=px 58px;
    margin-bottom: 8px;
  }

  .title-small {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    font-feature-settings: "ss04" on, "ss03" on, "ss02" on, "ss06" on, "ss07" on;
    color: #423d33;
    margin: 16px 0px;
  }

  .explanation {
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    font-feature-settings: "ss04" on, "ss03" on, "ss02" on, "ss06" on, "ss07" on;
    color: #423d33;
    margin: 16px 24px;
  }

  .img {
    width: 83.2%;
    height: auto;
    margin-top: 16px;
  }

  @media (min-width: 768px) {
    .img {
      width: 64.58%;
      height: auto;
    }
    .mobile {
      display: none;
    }
  }

  .holder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .introduction-box {
    margin: 0px 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .main-box {
    /* margin-bottom: 124px; */
  }

  .console {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    transform: translateY(-20px);
    overflow: visible;
    height: 46px;
  }

  .img-console {
    width: 327px;
    height: 92px;
    margin-right: 24px;
    margin-left: 24px;
    transition: width 1000ms;
    width: 80%;
    height: auto;
    overflow: visible;
  }

  @media (max-width: 767px) {
    .other {
      display: none;
    }
    .mobile {
      width: 48px;
    }
  }

  @media (min-width: 458px) {
    .img-console {
      width: 60%;
    }
  }

  @media (min-width: 768px) {
    .prevArrow,
    .nextArrow {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 75px;
      transition: height 1000ms;

      position: absolute;
      z-index: 5;
    }

    .prevArrow {
      left: 10px;
    }

    .nextArrow {
      left: 100vw;
      transform: translateX(-85px);
    }

    .mobile {
      display: none;
    }
    .title-large {
      font-size: 40px;
      line-height: 48px;
      margin-top: 48px;

      .introduction {
        width: 534px;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        font-feature-settings: "ss04" on, "ss03" on, "ss02" on, "ss06" on,
          "ss07" on;
      }

      .title-medium {
        font-size: 40px;
        line-height: 48px;
        width: 532px;
        margin: 0px;
        margin-top: 32px;
        margin-bottom: 56px;
      }

      .title-small {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        color: #423d33;
      }
    }

    .explanation-centering {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    .explanation {
      font-size: 18px;
      line-height: 24px;
      color: #423d33;
      width: 536px;
    }

    .console {
      transform: translateY(-20px);
      overflow: visible;
      height: 46px;
    }

    .img-console {
      margin-right: 166px;
      margin-left: 166px;
      transition: width 1000ms;
      width: 100%;
      height: auto;
      overflow: visible;
    }
  }

  @media (min-width: 1376px) {
    border-top: none;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .main-box {
      background: #e3f9e5;
      width: 1376px;
      border-radius: 8px 8px 0px 0px;
      margin-bottom: 0px;
      border: 1px solid #e8e6e1;
    }
    .slick-dots {
      width: 1376px;
      border-radius: 0px 0px 8px 8px;
      border: 1px solid #e8e6e1;
      border-top: none;
    }
  }

  @media (min-width: 1024px) {
    display: none;
  }

  ///////////////////////////

  .slick-arrow {
    width: 48px;
    height: 48px;
  }

  .slick-prev:before {
    content: none !important;
  }

  .slick-next:before {
    content: none !important;
  }

  .slick-next {
    transform: translate3d(-120px, 135px, 0px);
  }
  .slick-prev {
    transform: translate3d(120px, 135px, 0px);
    z-index: 1;
  }

  @media (max-width: 767px) {
    .slick-arrow {
      display: none;
    }
  }
  .slick-dots {
    background: #e3f9e5;
    padding-top: 32px;
    transform: translateY(75px);
    height: 100px;
    border-bottom: 1px solid #e8e6e1;
  }

  .slick-dots li button:before {
    content: "" !important;
    font-weight: 900 !important;
    width: 10px !important;
    height: 10px !important;
    font-size: 18px !important;
    opacity: 1 !important;
    border: 3px !important;
    border-radius: 24px;
    border-style: solid !important;
    border-color: #05400a !important;
  }

  .slick-dots li.slick-active button:before {
    content: "" !important;
    opacity: 1 !important;
    background: #05400a !important;
  }

  @media (min-width: 1440px) {
    .slick-dots {
      width: 100% !important;
    }
  }
  /////////////////////////////
`

const SinglePlatformTabletMobileNew = () => {
  const [scrollBarX, setScrollBarX] = useState(() => 0)

  const carousel = useRef(null)
  const thumb = useRef(null)
  const scrollBar = useRef(null)
  const prev = useRef(null)
  const next = useRef(null)

  useEffect(() => {
    let grow_height = "6px"

    /// PRIVREMENA PRILAGODBA
    thumb.current.style.width = scrollBar.current.offsetWidth / 5 + "px"

    function SmoothHorizontalScrolling(e, time, amount, start) {
      var eAmt = amount / 100
      var curTime = 0
      var scrollCounter = 0
      while (curTime <= time) {
        window.setTimeout(SHS_B, curTime, e, scrollCounter, eAmt, start)
        curTime += time / 100
        scrollCounter++
      }
    }

    function SHS_B(e, sc, eAmt, start) {
      e.current.scrollLeft = eAmt * sc + start
    }

    const arrowMovement = direction => {
      let c_scroll_width = carousel.current.scrollWidth
      let c_width = carousel.current.offsetWidth
      let movement_factor = c_scroll_width - c_width

      SmoothHorizontalScrolling(
        carousel,
        275,
        window.innerWidth * direction,
        carousel.current.scrollLeft
      )

      thumb.current.style.left =
        (carousel.current.scrollLeft / movement_factor) *
          (scrollBar.current.offsetWidth - thumb.current.offsetWidth) +
        scrollBar.current.offsetLeft +
        "px"
    }

    prev.current.onmousedown = () => {
      arrowMovement(-1)
    }

    next.current.onmousedown = () => {
      arrowMovement(1)
    }

    thumb.current.style.left = scrollBar.current.offsetLeft + "px"

    ////////////// PC
    thumb.current.onmousedown = mc => {
      let down = true
      // thumb.style.height = grow_height

      // modularno centriranje thumba
      thumb.current.style.top =
        scrollBar.current.offsetTop -
        (thumb.current.offsetHeight - scrollBar.current.offsetHeight) / 2 +
        "px"

      document.onmouseup = mm => {
        if (down) {
          thumb.current.style.top = scrollBar.current.offsetTop + "px"
          down = false
          let c_scroll_width = carousel.current.scrollWidth
          let c_width = carousel.current.offsetWidth
          let movement_factor = c_scroll_width - c_width

          let thumb_width = thumb.current.offsetWidth
          /// go to pozicija ali odma se thumb centrira to jest oduzima se pola velicine

          let go_to = mm.x - thumb_width / 2
          if (go_to < scrollBar.current.offsetLeft)
            go_to = scrollBar.current.offsetLeft
          /// provjer koja uracunava marginu
          if (
            go_to >
            scrollBar.current.offsetLeft +
              scrollBar.current.offsetWidth -
              thumb_width
          )
            go_to =
              scrollBar.current.offsetLeft +
              scrollBar.current.offsetWidth -
              thumb_width

          var counts = [
              scrollBar.current.offsetLeft,
              scrollBar.current.offsetLeft + scrollBar.current.offsetWidth / 5,
              scrollBar.current.offsetLeft +
                (2 * scrollBar.current.offsetWidth) / 5,
              scrollBar.current.offsetLeft +
                (3 * scrollBar.current.offsetWidth) / 5,
              scrollBar.current.offsetLeft +
                (4 * scrollBar.current.offsetWidth) / 5,
            ],
            goal = go_to

          var closest = counts.reduce(function (prev, curr) {
            return Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev
          })

          go_to = closest

          /// scrolling funkcija koja nije funkcija
          carousel.current.scrollLeft =
            movement_factor *
            (Math.abs(scrollBar.current.offsetLeft - go_to) /
              (scrollBar.current.offsetWidth - thumb_width))

          thumb.current.style.left = go_to + "px"
        }
      }
      /// dobivamo koliko se piksela moze pomaknut pravi scrollbar
      /// poslje to mnozimo sa postotkom pomaka vizualnog scrollbara
      let c_scroll_width = carousel.current.scrollWidth
      let c_width = carousel.current.offsetWidth
      let movement_factor = c_scroll_width - c_width

      document.onmousemove = mm => {
        if (down) {
          let thumb_width = thumb.current.offsetWidth
          /// go to pozicija ali odma se thumb centrira to jest oduzima se pola velicine
          let go_to = mm.x - thumb_width / 2
          if (go_to < scrollBar.current.offsetLeft)
            go_to = scrollBar.current.offsetLeft
          /// provjer koja uracunava marginu

          if (
            go_to >
            scrollBar.current.offsetLeft +
              scrollBar.current.offsetWidth -
              thumb_width
          )
            go_to =
              scrollBar.current.offsetLeft +
              scrollBar.current.offsetWidth -
              thumb_width
          /// scrolling funkcija koja nije funkcija
          carousel.current.scrollLeft =
            movement_factor *
            (Math.abs(scrollBar.current.offsetLeft - go_to) /
              (scrollBar.current.offsetWidth - thumb_width))
          /// update pozicije vizualnog scroll bara
          thumb.current.style.left = go_to + "px"
        }
      }
    }

    ////////////////////////////// MOBILE
    thumb.current.addEventListener("touchstart", mc => {
      let down = true
      thumb.current.style.height = grow_height
      scrollBar.current.style.height = "4px"

      // modularno centriranje thumba
      thumb.current.style.top =
        scrollBar.current.offsetTop -
        (thumb.current.offsetHeight - scrollBar.current.offsetHeight) / 2 +
        "px"

      document.addEventListener("touchend", mm => {
        if (down) {
          thumb.current.style.top = scrollBar.current.offsetTop + "px"

          thumb.current.style.height = ""
          scrollBar.current.style.height = ""

          down = false

          let c_scroll_width = carousel.current.scrollWidth
          let c_width = carousel.current.offsetWidth
          let movement_factor = c_scroll_width - c_width

          let thumb_width = thumb.current.offsetWidth
          /// go to pozicija ali odma se thumb centrira to jest oduzima se pola velicine
          let go_to = mm.changedTouches[0].clientX - thumb_width / 2
          if (go_to < scrollBar.current.offsetLeft)
            go_to = scrollBar.current.offsetLeft
          /// provjer koja uracunava marginu
          if (
            go_to >
            scrollBar.current.offsetLeft +
              scrollBar.current.offsetWidth -
              thumb_width
          )
            go_to =
              scrollBar.current.offsetLeft +
              scrollBar.current.offsetWidth -
              thumb_width

          var counts = [
              scrollBar.current.offsetLeft,
              scrollBar.current.offsetLeft + scrollBar.current.offsetWidth / 5,
              scrollBar.current.offsetLeft +
                (2 * scrollBar.current.offsetWidth) / 5,
              scrollBar.current.offsetLeft +
                (3 * scrollBar.current.offsetWidth) / 5,
              scrollBar.current.offsetLeft +
                (4 * scrollBar.current.offsetWidth) / 5,
            ],
            goal = go_to

          var closest = counts.reduce(function (prev, curr) {
            return Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev
          })

          go_to = closest

          /// scrolling funkcija koja nije funkcija
          carousel.current.scrollLeft =
            movement_factor *
            (Math.abs(scrollBar.current.offsetLeft - go_to) /
              (scrollBar.current.offsetWidth - thumb_width))

          thumb.style.left = go_to + "px"
        }
      })
      /// dobivamo koliko se piksela moze pomaknut pravi scrollbar
      /// poslje to mnozimo sa postotkom pomaka vizualnog scrollbara
      let c_scroll_width = carousel.current.scrollWidth
      let c_width = carousel.current.offsetWidth
      let movement_factor = c_scroll_width - c_width

      document.addEventListener("touchmove", mm => {
        if (down) {
          let thumb_width = thumb.current.offsetWidth
          /// go to pozicija ali odma se thumb centrira to jest oduzima se pola velicine
          let go_to = mm.touches[0].clientX - thumb_width / 2
          if (go_to < scrollBar.current.offsetLeft)
            go_to = scrollBar.current.offsetLeft

          /// provjer koja uracunava marginu
          if (
            go_to >
            scrollBar.current.offsetLeft +
              scrollBar.current.offsetWidth -
              thumb_width
          )
            go_to =
              scrollBar.current.offsetLeft +
              scrollBar.current.offsetWidth -
              thumb_width
          /// scrolling funkcija koja nije funkcija
          carousel.current.scrollLeft =
            movement_factor *
            (Math.abs(scrollBar.current.offsetLeft - go_to) /
              (scrollBar.current.offsetWidth - thumb_width))
          /// update pozicije vizualnog scroll bara
          thumb.current.style.left = go_to + "px"
        }
      })
    })
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    window.addEventListener("resize", () => {
      thumb.current.style.top =
        scrollBar.current.offsetTop -
        (thumb.current.offsetHeight - scrollBar.current.offsetHeight) / 2 +
        "px"

      let c_scroll_width = carousel.current.scrollWidth
      let c_width = carousel.current.offsetWidth
      let movement_factor = c_scroll_width - c_width
      /// PRIVREMENA PRILAGODBA
      thumb.current.style.width = scrollBar.current.offsetWidth / 5 + "px"
      // thumb.style.width = 50 + "px"
      thumb.current.style.left =
        (carousel.current.scrollLeft / movement_factor) *
          (scrollBar.current.offsetWidth - thumb.current.offsetWidth) +
        scrollBar.current.offsetLeft +
        "px"
    })

    window.addEventListener("scroll", () => {
      thumb.current.style.top =
        scrollBar.current.offsetTop -
        (thumb.current.offsetHeight - scrollBar.current.offsetHeight) / 2 +
        "px"

      let c_scroll_width = carousel.current.scrollWidth
      let c_width = carousel.current.offsetWidth
      let movement_factor = c_scroll_width - c_width

      thumb.current.style.left =
        (carousel.current.scrollLeft / movement_factor) *
          (scrollBar.current.offsetWidth - thumb.current.offsetWidth) +
        scrollBar.current.offsetLeft +
        "px"
    })

    carousel.current.addEventListener("scroll", () => {
      let c_scroll_width = carousel.current.scrollWidth
      let c_width = carousel.current.offsetWidth
      let movement_factor = c_scroll_width - c_width

      thumb.current.style.left =
        (carousel.current.scrollLeft / movement_factor) *
          (scrollBar.current.offsetWidth - thumb.current.offsetWidth) +
        scrollBar.current.offsetLeft +
        "px"

      setScrollBarX(i => i)
    })
  }, [])

  return (
    <AnnualStyle>
      <div className="main-box">
        <div className="introduction-box">
          <div className="console">
            <StaticImage
              className="img-console"
              layout="constrained"
              src="../images/hero/console.png"
              alt="console image"
              placeholder="blurred"
              quality={100}
              loading="eager"
            />
          </div>
          <div className="title-large">
            One solution <br className="mobile" />
            for your <br className="tablet" /> entire <br className="mobile" />
            shop floor
          </div>
          <div className="introduction">
            Ascalia gives you a set of tailored tools to stay in control of your
            production.
          </div>
          {/* <div className="title-medium">
            What we enable is the new era of manufacturing:
          </div> */}
        </div>
        <div className="complete-carousel">
          <div className="prevArrow desktop" ref={prev}>
            <Prev className="other" />
            <PrevSmall className="mobile" />
          </div>
          <div className="carousel-costume" ref={carousel}>
            <div className="carousel-content">
              <div className="holder">
                <StaticImage
                  className="img1 img"
                  src="../images/era_of_manufacturing_ilustrations/efficiency.png"
                  layout="constrained"
                  alt="predictive maintenance image"
                  placeholder="blurred"
                  quality={100}
                  toformat="webp"
                  loading="eager"
                />
                <div className="title-small">
                  Efficiency Analysis & Optimisation
                </div>
                <div className="explanation-centering">
                  <div className="explanation">
                    Real-Time analysis of production efficiency with live alerts
                    and optimisation tips
                  </div>
                </div>
              </div>
              <div className="holder">
                <StaticImage
                  className="img1 img"
                  src="../images/era_of_manufacturing_ilustrations/ai_quality.png"
                  layout="constrained"
                  alt="predictive maintenance image"
                  placeholder="blurred"
                  quality={100}
                  loading="eager"
                />
                <div className="title-small">Ai Powered QA</div>
                <div className="explanation-centering">
                  <div className="explanation">
                    Analysing product quality in all the key place on the line,
                    reducing rework and defects
                  </div>
                </div>
              </div>
              <div className="holder">
                <StaticImage
                  className="img1 img"
                  src="../images/era_of_manufacturing_ilustrations/predictive_maintenance.png"
                  layout="constrained"
                  alt="predictive maintenance image"
                  placeholder="blurred"
                  quality={100}
                  toformat="webp"
                  loading="eager"
                />
                <div className="title-small">Predictive maintenace</div>
                <div className="explanation-centering">
                  <div className="explanation">
                    Reduce production downtime and maintenance costs with
                    properly timed maintenance
                  </div>
                </div>
              </div>
              <div className="holder">
                <StaticImage
                  className="img1 img"
                  src="../images/era_of_manufacturing_ilustrations/tracking.png"
                  layout="constrained"
                  alt="predictive maintenance image"
                  placeholder="blurred"
                  quality={100}
                  toformat="webp"
                  loading="eager"
                />
                <div className="title-small">Production Tracking</div>
                <div className="explanation-centering">
                  <div className="explanation">
                    Innovative traceability solutions tailored for manufacturing
                    industry and batch manufacturing
                  </div>
                </div>
              </div>
              <div className="holder">
                <StaticImage
                  className="img1 img"
                  src="../images/era_of_manufacturing_ilustrations/work_planning.png"
                  layout="constrained"
                  alt="predictive maintenance image"
                  placeholder="blurred"
                  quality={100}
                  toformat="webp"
                  loading="eager"
                />
                <div className="title-small">Work Attendance & Planning</div>
                <div className="explanation-centering">
                  <div className="explanation">
                    Organise your workforce and increase efficiency with
                    transparent work organisation
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="nextArrow desktop" ref={next}>
            <Next className="other" />
            <NextSmall className="mobile" />
          </div>
        </div>
        <div className="scroll-bar-background">
          <div className="scroll-bar" ref={scrollBar}>
            <div className="thumb" ref={thumb}></div>
          </div>
        </div>
      </div>
    </AnnualStyle>
  )
}

export default SinglePlatformTabletMobileNew
