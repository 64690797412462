import * as React from "react"
import styled from "styled-components"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { Global, css } from "@emotion/react"
import HowDoesItWork from "../components/how_does_it_work"
import PartnerLogos from "../components/partner_logos"
import WhatDoCostumerSayNew from "../components/what_do_costumers_say_new"
import OurPartnership from "../components/our_partnership"
import FAQMobile from "../components/FAQ_mobile"
import FAQOther from "../components/FAQ_other"
import Cta from "../components/CTA"
import AboutUs from "../components/about_us"
import SupportBy from "../components/support_by"
import SinglePlatformTabletMobileNew from "../components/single_platform_for_all_mobile_tablet_new"
import SinglePlatformOther from "../components/single_platform_for_all_other"
import Hero from "../components/hero"
import Header from "../components/header"
import Seo from "../components/seo"
import TempLogos from "../components/partner_logos_temporary"
import PopUpCard from "../components/elements/popUpCard"
const AnnualStyle = styled.section`
  font-family: "Roboto";
  font-style: normal;
  background: #fcfdfc;

  body {
    margin: 0px !important;
  }

  .clip {
    overflow: clip;
    overflow: hidden;
  }

  @media (min-width: 1440px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    .clip {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 100vw;
    }
  }
`

const IndexPage = props => {
  return (
    <AnnualStyle>
      <Global
        styles={css`
          body {
            margin: 0;
          }
          html {
            margin: 0;
            overflow-x: hidden;
          }
        `}
      />
      <Seo title="HomePage" />
      <Header className="sticky"></Header>

      <div className="clip">
        <Hero></Hero>
        {/* <PopUpCard
          title="Production digitalisation"
          description="Seamless integration with machinery, providing paperless production
          and remote management."
          active={false}
          circle_x="47%"
          circle_y="75%"
          box_x="20px"
          box_y="20px"
        ></PopUpCard> */}
        <SinglePlatformOther></SinglePlatformOther>
        <SinglePlatformTabletMobileNew></SinglePlatformTabletMobileNew>
        <HowDoesItWork></HowDoesItWork>
        {/* <WhatDoCostumerSayNew></WhatDoCostumerSayNew> */}
        <TempLogos></TempLogos>
        <OurPartnership></OurPartnership>
        <PartnerLogos></PartnerLogos>
        {/* <FAQMobile></FAQMobile> */}
        {/* <FAQOther></FAQOther> */}
        <Cta></Cta>
        <AboutUs></AboutUs>
        <SupportBy></SupportBy>
      </div>
    </AnnualStyle>
  )
}

export default IndexPage

// export const query = graphql`
//   query {
//     img1: file(relativePath: { eq: "icon.png" }) {
//       childImageSharp {
//         gatsbyImageData(
//           width: 300
//           blurredOptions: { width: 500 }
//           placeholder: BLURRED
//           transformOptions: { cropFocus: CENTER }
//           aspectRatio: 0.7
//         )
//       }
//     }
//   }
// `;
