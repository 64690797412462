import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { Link, navigate } from "gatsby"

const AnnualStyle = styled.section`
  padding-bottom: 40px;
  .img1 {
    width: 164px;
    height: 60px;
  }

  .img2 {
    width: 238px;
    height: 60px;
  }

  .img3 {
    width: 178px;
    height: 60px;
  }

  .img4 {
    width: 157px;
    height: 52px;
  }

  .img5 {
    width: 152.5px;
    height: 40px;
  }
  .img6 {
    width: 138px;
    height: 98.5px;
  }
  .img8 {
    width: 138px;
    height: 68.5px;
  }

  .img7 {
    width: 105px;
    height: 110px;
  }

  .img {
    margin: 12px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
  }
  .supported-by {
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    text-transform: capitalize;
    font-feature-settings: "cpsp";
    margin: 24px 0px;
    color: #b8b2a7;
  }

  .supported-holder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    max-width: 1310px;
    margin: auto;
  }

  @media (max-width: 1440px) {
    height: auto;
    .supported-holder {
      flex-wrap: wrap;
      .break {
        flex-basis: 100%;
      }
    }
    padding-bottom: 0;
  }

  @media (max-width: 765px) {
    height: 100%;
    .supported-holder {
      .break {
        flex-basis: 0%;
      }
    }
  }

  @media (min-width: 768px) {
    .supported-holder {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      text-align: center;
      height: 300px;
    }

    .img {
      margin: 12px 20px;
    }

    .img1 {
      width: 130px;
      height: 48px;
    }

    .img2 {
      width: 190px;
      height: 48px;
    }

    .img3 {
      width: 144px;
      height: 48px;
    }
  }

  @media (min-width: 1440px) {
    padding-top: 24px;
  }
`

const SupportBy = () => {
  return (
    <AnnualStyle>
      <div className="supported-holder">
        <div className="supported-by">Supported By</div>
        <div className="img1 img">
          <StaticImage
            src="../images/supported_by/climate_kic.png"
            layout="constrained"
          />
        </div>
        <div className="img2 img">
          <StaticImage
            src="../images/supported_by/eu.png"
            layout="constrained"
          />
        </div>
        <div className="img3 img">
          <StaticImage
            className="unedited-image"
            src="../images/supported_by/frc.png"
            layout="constrained"
          />
        </div>
        <div className="break"></div>
        <div
          onClick={() =>
            navigate("/projekt-integrator-tvrtke-intis-engineering")
          }
          className="img4 img"
        >
          <StaticImage
            src="../images/supported_by/ESI.png"
            layout="constrained"
          />
        </div>
        <div
          onClick={() =>
            navigate("/projekt-integrator-tvrtke-intis-engineering")
          }
          className="img5 img"
        >
          <StaticImage
            src="../images/supported_by/OPKK.png"
            layout="constrained"
          />
        </div>

        <div
          onClick={() =>
            navigate("/projekt-integrator-tvrtke-intis-engineering")
          }
          className="img6 img"
        >
          <StaticImage
            src="../images/supported_by/EU_amblem.png"
            layout="constrained"
          />
        </div>

        <div
          onClick={() => navigate("/projekt-dokazivanje-koncepta-kamere")}
          className="img7 img"
        >
          <StaticImage
            src="../images/projekt_dokazivanje_koncepta_kamere/NextGenerationEU_funding.png"
            layout="constrained"
          />
        </div>

        <div
          onClick={() =>
            navigate(
              "https://eeagrants.org/archive/2014-2021/projects/HR-INNOVATION-0034"
            )
          }
          className="img8 img"
        >
          <StaticImage
            src="../images/supported_by/inno_eeagrants.png"
            layout="constrained"
          />
        </div>
      </div>
    </AnnualStyle>
  )
}

export default SupportBy
