import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { navigate } from "gatsby-link"
import { Link } from "gatsby"

const ButtonStyle = styled.div`
  button {
    border: none;
  }
  .small {
    padding: 8px 16px;
    width: "98px";
    height: "34px";
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    border-radius: 4px;
  }

  .big {
    padding: 17px 32px;
    width: 160px;
    height: 48px;
  }

  .green {
    background: #2f8132;
    color: #e3f9e5;
  }

  .green:hover {
    background: #57ae5b;
    color: #fcfdfc;
  }

  .green:active {
    background: #57ae5b;
    color: #fcfdfc;
    box-shadow: inset 0px 2px 2px rgba(14, 88, 20, 0.25);
  }

  .white {
    background: #faf9f7;
    color: #2f8132;
  }

  .white:hover {
    background: #fcfdfc;
    color: #57ae5b;
  }

  .white:active {
    background: #fcfdfc;
    box-shadow: inset 0px 2px 2px rgba(14, 88, 20, 0.25);
    color: #57ae5b;
  }
`
const ButtonC = props => {
  return (
    <ButtonStyle>
      <Link to={props.link}>
        <button
          className={`${props.color} ${props.size}`}
          onClick={props.action}
        >
          {props.text}
        </button>
      </Link>
    </ButtonStyle>
  )
}

ButtonC.defaultProps = {
  size: "big",
  color: "white",
}

export default ButtonC
