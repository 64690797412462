import React from "react"
import styled from "styled-components"
import InteractiveButton from "./popUpCard"

const TitleStyle = styled.div`
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  text-transform: capitalize;
  color: #423d33;

  @media (min-width: 768px) {
    font-size: 40px;
    line-height: 48px;
  }

  @media (min-width: 1440px) {
    font-size: 48px;
    line-height: 56px;
  }
`

function Title(props) {
  return (
    <TitleStyle>
      {" "}
      <div style={{ color: props.color }}>{props.title}</div>
    </TitleStyle>
  )
}

Title.defaultProps = {
  title: "title",
  color: "#423d33",
}

export default Title
