import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

const AnnualStyle = styled.section`
  padding: 0px 24px;

  .gray {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  }

  .logos {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    white-space: normal;
    background: #faf9f7;
    border: 1px solid #e8e6e1;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 8px 24px;
  }

  .static {
    margin: 16px 0px;
  }

  @media (max-width: 374px) {
    .static {
      flex-basis: 100px;
    }

    .elos {
      flex-basis: 100px;
    }

    .smartsense {
      flex-basis: 100px;
    }

    .ziggpro {
      flex-basis: 100px;
    }

    .koncar {
      flex-basis: 100px;
    }

    .barting {
      flex-basis: 100px;
    }

    .sigfox {
      flex-basis: 100px;
    }

    .iotnet {
      flex-basis: 100px;
    }
  }

  @media (min-width: 375px) {
    .static {
      flex-basis: 120px;
    }

    .elos {
      flex-basis: 120px;
    }

    .smartsense {
      flex-basis: 120px;
    }

    .ziggpro {
      flex-basis: 120px;
    }

    .koncar {
      flex-basis: 120px;
    }

    .barting {
      flex-basis: 120px;
    }

    .sigfox {
      flex-basis: 120px;
    }

    .iotnet {
      flex-basis: 120px;
    }
  }

  @media (max-width: 767px) {
    .tablet {
      display: none;
    }
  }

  @media (max-width: 576px) {
    break {
      flex-basis: none;
      width: 0px;
      height: 0px;
      overflow: hidden;
      display: none;
      order: -1;
    }
  }

  @media (min-width: 576px) {
    @media (max-width: 768px) {
      break {
        flex-basis: 100%;
        width: 0px;
        height: 0px;
        overflow: hidden;
        order: 4;
      }

      .barting {
        order: 5;
      }

      .sigfox {
        flex-basis: 100px;
        order: 6;
      }

      .iotnet {
        order: 7;
      }
    }
  }

  @media (min-width: 768px) {
    padding: 0px 48px;

    .logos {
      justify-content: space-evenly;
    }
    .mobile {
      display: none;
    }

    .logos {
      padding: 48px;
    }

    .static {
      margin: 16px 16px;
    }

    .elos {
      flex-basis: 94.34px;
    }

    .smartsense {
      flex-basis: 112px;
    }

    .ziggpro {
      flex-basis: 104px;
    }

    .koncar {
      flex-basis: 82.29px;
    }

    break {
      flex-basis: 100%;
      width: 0px;
      height: 0px;
      overflow: hidden;
      order: 4;
    }

    .barting {
      flex-basis: 128px;
      order: 5;
      margin-top: 48px;
    }

    .sigfox {
      flex-basis: 100px;
      order: 6;
      margin-top: 48px;
    }

    .iotnet {
      flex-basis: 89.76px;
      order: 7;
      margin-top: 48px;
    }
  }

  @media (min-width: 1024px) {
    .iotnet {
      margin-right: 96px;
    }
  }

  @media (min-width: 1440px) {
    .logos {
      width: 1376px;
      margin-top: 32px;
      padding: 36px 32px;
    }

    break {
      flex-basis: none;
      width: 0px;
      height: 0px;
      overflow: hidden;
      order: -1;
    }

    .barting {
      order: 0;
      margin: 0;
    }
    .koncar {
      order: 1;
    }

    .smartsense {
      order: 2;
    }

    .elos {
      order: 3;
    }

    .iotnet {
      order: 4;
      margin: 0;
    }
    .sigfox {
      order: 5;
      margin: 0;
    }

    .ziggpro {
      order: 6;
    }
  }

  @media (min-width: 1376px) {
    .logos {
      /* width: 1376px; */
    }
  }
`

const PartnerLogos = () => {
  return (
    <AnnualStyle>
      <div className="logos mobile">
        <break></break>
        <StaticImage
          tag="section"
          className="static elos"
          quality={100}
          width={346}
          height={120}
          layout="constrained"
          placeholder="blurred"
          src="../images/logos/mobile/logo-elos.png"
          toFormat="PNG"
          alt="Elos logo"
        ></StaticImage>

        <StaticImage
          tag="section"
          className="static barting"
          quality={100}
          width={498}
          height={120}
          layout="constrained"
          placeholder="blurred"
          src="../images/logos/mobile/logo-barting.png"
          toFormat="PNG"
          alt="Barting logo"
        ></StaticImage>

        <StaticImage
          tag="section"
          className="static smartsense"
          quality={100}
          width={414}
          height={120}
          layout="constrained"
          placeholder="blurred"
          src="../images/logos/mobile/logo-smartsense.png"
          toFormat="PNG"
          alt="SmartSense logo"
        ></StaticImage>

        <StaticImage
          tag="section"
          className="static sigfox"
          quality={100}
          width={520}
          height={167}
          layout="constrained"
          placeholder="blurred"
          src="../images/logos/mobile/logo-sigfox.png"
          toFormat="PNG"
          alt="Sigfox logo"
        ></StaticImage>

        <StaticImage
          tag="section"
          className="static ziggpro"
          quality={100}
          width={390}
          height={120}
          layout="constrained"
          placeholder="blurred"
          src="../images/logos/mobile/logo-zigg-pro.png"
          toFormat="PNG"
          alt="ZiGGPRO logo"
        ></StaticImage>

        <StaticImage
          tag="section"
          className="static iotnet"
          quality={100}
          width={319}
          height={120}
          layout="constrained"
          placeholder="blurred"
          src="../images/logos/mobile/logo-iotnet.png"
          toFormat="PNG"
          alt="IOTNET logo"
        ></StaticImage>

        <StaticImage
          tag="section"
          className="static koncar"
          quality={100}
          width={304}
          height={120}
          layout="constrained"
          placeholder="blurred"
          src="../images/logos/mobile/logo-koncar.png"
          toFormat="PNG"
          alt="Koncar logo"
        ></StaticImage>
      </div>

      <div className="logos tablet">
        <break></break>
        <StaticImage
          tag="section"
          className="static elos"
          quality={100}
          layout="constrained"
          placeholder="blurred"
          src="../images/logos/tablet/logo-elos.png"
          toFormat="PNG"
          alt="Elos logo"
        ></StaticImage>

        <StaticImage
          tag="section"
          className="static barting"
          quality={100}
          layout="constrained"
          placeholder="blurred"
          src="../images/logos/tablet/logo-barting.png"
          toFormat="PNG"
          alt="Barting logo"
        ></StaticImage>

        <StaticImage
          tag="section"
          className="static smartsense"
          quality={100}
          layout="constrained"
          placeholder="blurred"
          src="../images/logos/tablet/logo-smartsense.png"
          toFormat="PNG"
          alt="SmartSense logo"
        ></StaticImage>

        <StaticImage
          tag="section"
          className="static sigfox"
          quality={100}
          layout="constrained"
          placeholder="blurred"
          src="../images/logos/tablet/logo-sigfox.png"
          toFormat="PNG"
          alt="Sigfox logo"
        ></StaticImage>

        <StaticImage
          tag="section"
          className="static ziggpro"
          quality={100}
          layout="constrained"
          placeholder="blurred"
          src="../images/logos/tablet/logo-zigg-pro.png"
          toFormat="PNG"
          alt="ZiGGPRO logo"
        ></StaticImage>

        <StaticImage
          tag="section"
          className="static iotnet"
          quality={100}
          layout="constrained"
          placeholder="blurred"
          src="../images/logos/tablet/logo-iotnet.png"
          toFormat="PNG"
          alt="IOTNET logo"
        ></StaticImage>

        <StaticImage
          tag="section"
          className="static koncar"
          quality={100}
          layout="constrained"
          placeholder="blurred"
          src="../images/logos/tablet/logo-koncar.png"
          toFormat="PNG"
          alt="Koncar logo"
        ></StaticImage>
      </div>
    </AnnualStyle>
  )
}

export default PartnerLogos
