import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { useEffect, useRef, useState } from "react"
import PopUpCard from "./elements/popUpCard"

import ContextHero from "../contexts/contextHero"

const AnnualStyle = styled.section`
  .hero-holder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100% !important;
    /* overflow: clip; */
  }

  .mobile {
    position: relative;
  }

  .card-title {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-transform: capitalize;
    color: #423d33;
    text-align: left;
  }

  .card-text {
    font-size: 14px;
    line-height: 24px;
    color: #625d52;
    text-align: left;
  }

  .circle {
    position: absolute;
    width: 50px;
    height: 50px;
    border: 1px solid rgba(80, 74, 64, 0.34);
    border-radius: 100%;
    background-color: none;
    z-index: 1;
    transition: width 500ms, height 500ms, transform 1000ms;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(1);
  }

  .circle1,
  .circle2,
  .circle3,
  .circle4 {
    transition: transform 2000ms;
  }

  .c3 {
    animation-delay: 500ms;
  }

  .pulsate {
    transition: transform 1200ms ease-in 0s;
    transform: scale(1.3);
  }

  /* @keyframes pulsate {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.3);
    }
  } */

  .circle-shadow {
    position: absolute;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    background: rgba(80, 74, 64, 0.34);
    transition: background-color 1000ms;
  }

  .circle-white {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    z-index: 1;
    background: #fcfdfc;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.55);
    opacity: 1 !important;
  }

  .circle1 {
    top: 24%;
    left: 63%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .box1 {
    top: 24%;
    left: 63%;
    position: absolute;
  }

  .circle2 {
    top: 34%;
    left: 16%;
    position: absolute;
    /* width: 1px;
    height: 1px; */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .box2 {
    top: 34%;
    left: 16%;
    position: absolute;
  }

  .circle3 {
    top: 47%;
    left: 53%;
    position: absolute;
    /* width: 1px;
    height: 1px; */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .box3 {
    top: 47%;
    left: 53%;
    position: absolute;
  }

  .circle4 {
    top: 75%;
    left: 40%;
    position: absolute;
    /* width: 1px;
    height: 1px; */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .box4 {
    top: 75%;
    left: 40%;
    position: absolute;
  }

  .hero-box {
    transition: opacity 1000ms;
    transform: translate3d(-70px, 52px, 0);
    position: absolute;
    width: 210px;
    /* height: 168px; */
    background: linear-gradient(
      180deg,
      rgba(227, 249, 229, 0.89) 0%,
      rgba(250, 249, 247, 0.55) 54.69%
    );
    backdrop-filter: blur(4px);
    border-radius: 8px;
    padding: 24px;
    opacity: 0;
    transition: opacity 1000ms;
    z-index: 100;
  }

  .box4 {
    transform: translate3d(-90px, 52px, 0);
  }

  @media (max-width: 767px) {
    .card-text {
      font-size: 12px;
      line-height: 20px;
    }
    .card-title {
      font-size: 14px;
      line-height: 16px;
    }

    .box1 {
      transform: translate3d(-185px, -114px, 0);
      z-index: 5;
    }

    .box3 {
      transform: translate3d(-155px, -104px, 0);
      z-index: 5;
    }

    .box4 {
      transform: translate3d(54px, -32px, 0);
    }

    .hero-box {
      height: auto;
    }

    .box1 {
      width: 162px;
    }

    .box3 {
      width: 145px;
      padding: 16px;
    }

    .box4 {
      width: 148px;
      padding: 16px;
    }

    @media (max-width: 374px) {
      .card-title {
        font-size: 10px;
        line-height: 14px;
        text-align: center;
      }

      .card-text {
        font-size: 10px;
        line-height: 14px;
        text-align: center;
      }

      .hero-box {
        width: 128px;
        height: auto;
        padding: 16px;
      }

      .box4 {
        transform: translate3d(48px, -32px, 0);
      }
    }
  }

  .circle-collision {
    width: 52px;
    height: 52px;
    z-index: 1;
  }

  .circle-collision:hover {
    .circle {
      transition: width 1000ms, height 1000ms;
      animation: none;
      width: 1px;
      height: 1px;
    }

    .circle-shadow {
      transition: background-color 1000ms;
      background-color: #2f8132;
    }
  }

  @media (hover: none) and (pointer: coarse) {
    .circle-collision:hover {
      .circle {
        transition: width 1000ms, height 1000ms;
        width: 1px;
        height: 1px;
      }
    }
  }

  .circle:active {
    transition: width 1000ms, height 1000ms, transform 1000ms;
    width: 1px;
    height: 1px;
    animation: none;
  }

  .active {
    opacity: 1;

    .circle {
      transition: width 1000ms, height 1000ms, transform 1000ms;
      width: 1px;
      height: 1px;
      animation: none;
    }
    .circle-shadow {
      transition: background-color 1000ms;
      background: rgba(80, 74, 64, 1);
    }
  }

  @keyframes zoom {
    0% {
      width: 40px;
      height: 40px;
    }
    100% {
      width: 1px;
      height: 1px;
    }
  }

  .hero-cta {
    background: linear-gradient(
      0deg,
      rgba(252, 253, 252, 0) 11.58%,
      #e3f9e5 88.48%
    );
    padding-bottom: 64px;
    width: 100%;
  }

  .background {
    width: 100%;
    height: auto;
    margin-bottom: 46px;
  }

  .title-large {
    font-weight: bold;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
    letter-spacing: -0.5px;
    text-transform: capitalize;
    color: #423d33;
    margin-bottom: 24px;
    margin-top: 24px;
  }

  .explanation {
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    font-feature-settings: "ss04" on, "ss03" on, "ss02" on, "ss06" on, "ss07" on;
    color: #504a40;
    margin: 24px 0px;
  }

  button {
    padding: 16px 32px;
    width: 177px;
    height: 46px;
    background: #2f8132;
    border-radius: 4px;
    margin: 8px 0px;
    font-weight: 700;
    font-size: 16px;
    line-height: 14px;
    text-align: center;
    color: #fbfefb;
    border: none;
  }

  .length {
    font-size: 14px;
    line-height: 16px;
  }

  @media (max-width: 767px) {
    .tablet {
      display: none;
    }

    .desktop {
      display: none;
    }
  }

  @media (max-width: 1439px) {
    .laptop {
      display: none;
    }
  }

  @media (max-width: 767px) {
    /* .desktop {
      display: none;
    } */
  }

  .circle-shadow {
    position: absolute;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    background: rgba(80, 74, 64, 0.34);
    transition: background-color 1000ms;
  }

  .circle-white {
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    z-index: 1;
    background: #fcfdfc;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.55);
    opacity: 1 !important;
  }

  @media (min-width: 768px) {
    .desktop {
      position: relative;
    }

    .mobile {
      display: none;
    }

    .tablet {
      position: relative;
    }

    .circle1 {
      top: 35.1%;
      left: 14.1%;
    }

    .box1 {
      top: 35.1%;
      left: 14.1%;
    }

    .circle2 {
      top: 12.7%;
      left: 56.9%;
    }

    .box2 {
      top: 12.7%;
      left: 56.9%;
    }

    .circle3 {
      top: 56.14%;
      left: 42.9%;
    }

    .box3 {
      top: 56.14%;
      left: 42.9%;
    }

    .circle4 {
      top: 48.23%;
      left: 86.12%;
    }

    .box4 {
      top: 48.23%;
      left: 76.12%;
    }

    .length {
      margin-top: 8px;
    }

    .background {
      width: 865px;
      height: 434px;
      width: 100%;
      height: auto;
    }

    .title-large {
      font-size: 48px;
      line-height: 56px;
      letter-spacing: -2px;
      width: 534px;
      margin-top: 96px;
      margin-bottom: 0px;
    }

    .explanation {
      font-size: 24px;
      line-height: 32px;
      margin: 24px 0px;
      width: 534px;
    }

    button {
      width: 160px;
      height: 45px;
      padding-right: 0px;
      padding-left: 0px;
      font-size: 16px;
      text-align: center;
      line-height: 14px;
      margin: 0px 10px;
      margin-top: 16px;
    }

    .hero-cta {
      width: 100vw;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  @media (min-width: 1440px) {
    .box4 {
      top: 48.23%;
      left: 86.12%;
    }

    .tablet {
      display: none;
    }

    .background {
      width: 100%;
      height: auto;
    }

    .title-large {
      font-size: 64px;
      line-height: 64px;
      letter-spacing: -2px;
      width: 724px;
      margin-top: 104px;
    }

    .explanation {
      font-size: 24px;
      line-height: 32px;
      margin: 40px 0px;
      width: 589px;
    }

    button {
      width: 160px;
      height: 45px;
      padding-right: 0px;
      padding-left: 0px;
      font-size: 16px;
      text-align: center;
      line-height: 14px;
      margin: 0px 10px;
    }

    .hero-cta {
      width: 100vw;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  @media (min-width: 1600px) {
    .background {
      width: 1600px;
      height: auto;
    }
  }

  .hide {
    display: none;
  }
`

const Hero = () => {
  return (
    <AnnualStyle>
      <ContextHero>
        <div className="hero-holder">
          <div className="hero-cta">
            <div className="title-large">
              Ascalia. Single <br className="mobile" /> platform.{" "}
              <br className="laptop" /> <br className="tablet" />
              For all <br className="mobile" />
              your needs.
            </div>
            <div className="explanation">
              Boost your profitability <br className="mobile" /> and
              productivity with ease.
            </div>
            <button className="hide">WATCH VIDEO</button>
            <div className="length hide">1.56 min long</div>
          </div>
          <div className="mobile background">
            <PopUpCard
              onClick={() => console.log("test")}
              title="Production digitalisation"
              description="Seamless integration with machinery, providing paperless production
          and remote management."
              active={false}
              circle_x="63%"
              circle_y="24%"
              box_x="-75px"
              box_y="60px"
              id={1}
            ></PopUpCard>

            <PopUpCard
              title="Real-time traceability"
              description="End-to-end production traceability for complete auditing and work
            order tracing in real-time"
              active={false}
              circle_x="53%"
              circle_y="56.14%"
              box_x="-70px"
              box_y="70px"
              id={2}
            ></PopUpCard>

            <PopUpCard
              title="AI powered QA"
              description="Tailored AI algorithms detect defects and issues early in the
            production process, reducing scrap and downtime"
              active={false}
              circle_x="40%"
              circle_y="75%"
              box_x="30px"
              box_y="170px"
              id={3}
            ></PopUpCard>

            <StaticImage
              tag="section"
              className="background mobile"
              quality="100"
              width={2400}
              height={2400}
              toFormat="PNG"
              placeholder="blurred"
              src="../images/hero/hero_mobile.png"
              alt="hero image"
            ></StaticImage>
          </div>
          <div className="desktop background">
            <PopUpCard
              title="Production digitalisation"
              description="Seamless integration with machinery, providing paperless production
          and remote management."
              active={false}
              circle_x="14.1%"
              circle_y="35.1%"
              box_x="-90px"
              box_y="20px"
              id={1}
            ></PopUpCard>

            <PopUpCard
              title="Real-time traceability"
              description="End-to-end production traceability for complete auditing and work
            order tracing in real-time"
              active={false}
              circle_x="42.9%"
              circle_y="56.14%"
              box_x="-100px"
              box_y="20px"
              id={2}
            ></PopUpCard>

            <PopUpCard
              title="AI powered QA"
              description="Tailored AI algorithms detect defects and issues early in the
            production process, reducing scrap and downtime"
              active={false}
              circle_x="86.12%"
              circle_y="48.23%"
              box_x="-150px"
              box_y="20px"
              id={3}
            ></PopUpCard>

            <StaticImage
              tag="section"
              className="background desktop"
              quality={100}
              width={3200}
              height={900}
              toFormat="PNG"
              placeholder="blurred"
              src="../images/hero/hero_other.png"
              alt="hero image"
            ></StaticImage>
          </div>
        </div>
      </ContextHero>
    </AnnualStyle>
  )
}

export default Hero
