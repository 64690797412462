import { createContext, useState } from "react"
import React from "react"

export const HeroPopUpContext = createContext({})

function ContextHero({ children }) {
  /// active popup is haves equal id to state value
  const [popActive, setPopActive] = useState(3)

  return (
    <HeroPopUpContext.Provider value={{ setPopActive, popActive }}>
      {children}
    </HeroPopUpContext.Provider>
  )
}

export default ContextHero
