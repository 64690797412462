import * as React from "react"
import styled from "styled-components"
import { useState } from "react"
import Logo from "../images/header/logo_small.svg"
import Open from "../images/header/open.svg"
import Prev from "../images/how_does_it_work/left.svg"
import { Link } from "gatsby"
import { useEffect } from "react"
import { navigate } from "@reach/router"
import ButtonC from "./elements/button"

const HeaderStyle = styled.section`
  position: sticky !important;
  top: 0px;
  z-index: 1000;

  .arrow-open {
    padding-top: 2px;
  }

  .header-holder {
    border-bottom: 1px solid #e8e6e1;
  }

  .header-background {
    background-image: url(./path/something.svg);
  }

  .logo-home {
    cursor: pointer;
  }

  header {
    background-color: #fcfdfc;
    height: 80px;
    width: 100%;
    position: -webkit-sticky;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .dashboard {
    text-align: center;
    background: none;
    width: 100%;
    height: 100vh;
    background: linear-gradient(
      180deg,
      rgba(227, 249, 229, 0.95) 0%,
      rgba(250, 249, 247, 0.95) 54.69%
    );
    backdrop-filter: blur(5px);
    position: absolute;

    float: none;
  }

  .dashboard-element {
    font-weight: bold;
    padding: 32px 0px;
    font-size: 24px;
    line-height: 32px;
    text-transform: capitalize;
    color: red;
  }

  button {
    margin: 0;
    color: #e3f9e5;
    background: #2f8132;
    border-radius: 4px;
    padding: 10px 32px;
    text-transform: capitalize;
    border: none;
    margin-top: 48px;
  }

  .back-arrow {
    position: absolute;
    top: 8px;
    left: 24px;
  }

  .header-element:hover {
    color: #2f8132;
    cursor: pointer;
  }

  .header-element:active {
    color: #423d33;
    color: #625d52;
  }

  button:hover {
    color: #fcfdfc;
    background: #57ae5b;
  }

  button:active {
    color: #fcfdfc;
    background: #57ae5b;
    box-shadow: inset 0px 2px 2px rgba(14, 88, 20, 0.25);
  }

  @media (max-width: 767px) {
    .other {
      //izabaci
      /* display: none; */
    }
  }

  @media (min-width: 320px) {
    /// izbrisi ovo to jest prepravi!!!!
    .mobile {
      display: none;
    }

    header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      width: 100%;
      padding: 0px 48px;
    }

    .middle {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    .right {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    .header-element {
      font-weight: 600;
      font-size: 16px;
      line-height: 18px;
      font-feature-settings: "cpsp" on;
      margin: 0px 10px;
      color: #423d33;
      text-decoration: none;
    }

    button {
      padding: 0;
      width: 70px;
      height: 34px;
      background: #2f8132;
      border-radius: 4px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
      color: #fbfefb;
      margin: 0px;
      border: none;
    }

    .header-holder {
      background-color: #fcfdfc;
      padding: 0px;
    }
    .header-holder {
      width: 100vw;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  //izbrisi ovo
  @media (max-width: 767px) {
    .header-holder {
      padding: 0;
    }

    header {
      padding: 0px 24px 0 24px;
      width: 100%;
    }

    button {
      padding: 0;
      width: 70px;
      height: 34px;
      background: #2f8132;
      border-radius: 4px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
      color: #fbfefb;
      margin: 0px;
      border: none;
    }
  }

  @media (min-width: 1440px) {
    header {
      padding: 0;
      max-width: 1376px;
    }
  }

  .hide {
    display: none;
  }
  button {
    width: auto;
    padding: 8px 16px;
  }
  .blog {
    margin-right: 24px;
  }

  @media (min-width: 768px) {
    .blog {
      margin-right: 48px;
    }
    .sign-in {
      margin-right: 32px;
    }
  }

  @media (max-width: 440px) {
    header {
      flex-direction: column;
      justify-content: flex-start;
      gap: 24px;
      padding-top: 16px;
      padding-bottom: 16px;
      height: auto;
    }
  }
`
const Header = () => {
  const [show, setShow] = useState(() => false)

  // useEffect(() => {
  //   // document.querySelector(".logo-home").addEventListener("click", () => {
  //   //   console.log("works")
  //   //   navigate("/")
  //   // })
  //   // document.querySelector(".sign-in").addEventListener("click", () => {
  //   //   navigate("https://app.ascalia.io/login/?next=/")
  //   // })
  // }, [])

  let showHeader = () => {
    return show ? (
      <header>
        <Prev className="back-arrow" />
        <div
          className="logo"
          role="button"
          tabIndex={0}
          onKeyDown={() => setShow(show => !show)}
          onClick={() => setShow(show => !show)}
        >
          <Logo />
        </div>
      </header>
    ) : (
      <header>
        <div
          className="logo"
          role="button"
          tabIndex={0}
          onKeyDown={() => setShow(show => !show)}
          onClick={() => setShow(show => !show)}
        >
          <Logo />
          <Open className="arrow-open" />
        </div>
      </header>
    )
  }

  let showDashboard = () => {
    return show ? (
      <div className="dashboard">
        <div className="dashboard-element">Products</div>
        <div className="dashboard-element">Contact</div>
        <div className="dashboard-element">Company</div>
        <button className=""> Shedule A demo </button>
      </div>
    ) : (
      <div></div>
    )
  }

  const goToForm = () => {
    navigate("/schedule")
  }

  return (
    <HeaderStyle>
      <div className="mobile hide">
        {showHeader()}
        {showDashboard()}
      </div>
      <div className=" other">
        <div className="header-holder">
          <header>
            <div>
              <Logo className="logo-home" onClick={() => navigate("/")} />
            </div>
            <div className="middle hide">
              <div className="header-element">Products</div>
              <div className="header-element">About</div>
              <div className="header-element">Contact</div>
            </div>
            <div className="right">
              <div
                className="header-element sign-in"
                onClick={() => navigate("https://app.ascalia.io/login/?next=/")}
              >
                Sign In
              </div>
              <a
                className="header-element blog"
                href="https://medium.com/ascaliaio"
                target="_blank"
                rel="noopener noreferrer"
              >
                Blog
              </a>
              <ButtonC
                size="small"
                color="green"
                text="Contact Us"
                // action=""
                link="/schedule"
              />
            </div>
          </header>
        </div>
      </div>
    </HeaderStyle>
  )
}

export default Header
