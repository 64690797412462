import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

const AnnualStyle = styled.section`
  .logos2 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    margin: 64px 24px;
  }

  .static {
    margin: 40px 0;
  }

  .klana,
  .sainsburys,
  .omco {
    flex-basis: 124px;
  }
  .ina,
  .iverpan,
  .dukat {
    flex-basis: 124px;
  }

  .title {
    font-weight: bold;
    font-size: 32px;
    line-height: 32px;
    margin: 64px 0;
    text-align: center;
  }

  @media (min-width: 375px) {
    .klana,
    .sainsburys,
    .omco {
      flex-basis: 120px;
    }
    .ina,
    .iverpan,
    .dukat {
      flex-basis: 120px;
    }
  }

  @media (min-width: 768px) {
    .title {
      font-weight: bold;
      font-size: 32px;
      line-height: 48px;
      margin: 72px 0;
    }

    break {
      flex-basis: 100%;
      width: 0px;
      height: 0px;
      overflow: hidden;
    }

    .klana,
    .sainsburys,
    .omco {
      flex-basis: 144px;
    }
    .ina,
    .iverpan,
    .dukat {
      flex-basis: 144px;
    }

    .static {
      margin: 40px 24px;
    }
  }
  @media (min-width: 529px) {
    break {
      flex-basis: 100%;
      width: 0px;
      height: 0px;
      overflow: hidden;
    }
  }

  @media (min-width: 1024px) {
    break {
      flex-basis: 0;
      width: 0px;
      height: 0px;
      overflow: hidden;
      display: none;
      order: -1;
    }

    .title {
      font-weight: bold;
      font-size: 32px;
      line-height: 48px;
      margin: 72px 0;
    }

    .klana,
    .sainsburys,
    .omco {
      flex-basis: 112px;
    }
    .ina,
    .iverpan,
    .dukat {
      flex-basis: 112px;
    }
  }

  @media (min-width: 1376px) {
    .logos2 {
      width: 1376px;
    }

    break {
      display: none;
    }
  }

  @media (min-width: 1440px) {
    .title {
      font-weight: bold;
      font-size: 32px;
      line-height: 56px;
      margin: 80px 0;
    }
    .klana,
    .sainsburys,
    .omco {
      flex-basis: 160px;
    }
    .ina,
    .iverpan,
    .dukat {
      flex-basis: 160px;
    }
  }
`

const TempLogos = () => {
  return (
    <AnnualStyle>
      <div className="title">Who We Helped</div>
      <div className="logos2 ">
        <StaticImage
          tag="section"
          className="static iverpan"
          quality={100}
          layout="constrained"
          placeholder="blurred"
          src="../images/who_we_helped/iverpan.png"
          toformat="PNG"
          alt="Barting logo"
        ></StaticImage>

        <StaticImage
          tag="section"
          className="static ina"
          quality={100}
          layout="constrained"
          placeholder="blurred"
          src="../images/who_we_helped/ina.png"
          toformat="PNG"
          alt="SmartSense logo"
        ></StaticImage>

        <StaticImage
          tag="section"
          className="static dukat"
          quality={100}
          layout="constrained"
          placeholder="blurred"
          src="../images/who_we_helped/dukat.png"
          toformat="PNG"
          alt="Sigfox logo"
        ></StaticImage>
        <break></break>
        <StaticImage
          tag="section"
          className="static klana"
          quality={100}
          layout="constrained"
          placeholder="blurred"
          src="../images/who_we_helped/klana.png"
          toformat="PNG"
          alt="ZiGGPRO logo"
        ></StaticImage>

        <StaticImage
          tag="section"
          className="static sainsburys"
          quality={100}
          layout="constrained"
          placeholder="blurred"
          src="../images/who_we_helped/sainsburys.png"
          toformat="PNG"
          alt="IOTNET logo"
        ></StaticImage>

        <StaticImage
          tag="section"
          className="static omco"
          quality={100}
          layout="constrained"
          placeholder="blurred"
          src="../images/who_we_helped/omco.png"
          toformat="PNG"
          alt="Koncar logo"
        ></StaticImage>
      </div>
    </AnnualStyle>
  )
}

export default TempLogos
