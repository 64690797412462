import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { useState, useEffect } from "react"
import Prev from "../images/how_does_it_work/left.svg"
import PrevSmall from "../images/how_does_it_work/leftSmall.svg"
import Next from "../images/how_does_it_work/right.svg"
import NextSmall from "../images/how_does_it_work/rightSmall.svg"
import Title from "./elements/title"

const AnnualStyle = styled.section`
  padding: 94px 24px;
  padding-bottom: 40px;
  height: 667px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #e3f9e5 0%, #faf9f7 54.69%);
  font-style: normal;
  border-bottom: 1px solid #e8e6e1;
  border-top: 1px solid #e8e6e1;
  margin-top: 24px;
  max-width: 1376px;

  .img1 {
    max-height: 352px !important;
    max-width: 565px;
    width: 100%;
    height: auto;
  }

  .title {
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    color: #423d33;
    text-transform: capitalize;
    text-align: center;
    width: 100vw;
    transform: translateY(-64px);
  }

  .holder {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    text-align: center;
    margin: auto;
    /* width: 100vw !important; */
  }

  .paging-holder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    text-align: center;
    width: 100%;
    transition: opacity 1000ms;
    opacity: 0.3;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  ul,
  li {
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .line {
    height: 3px;
    background: black;
  }

  .step {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    font-feature-settings: "ss04" on, "ss03" on, "ss02" on, "ss06" on, "ss07" on;
    color: #2f8132;
    margin: 16px 0px;
  }

  .title-small {
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    color: #423d33;
    text-align: center;
    margin-bottom: 16px;
  }

  p {
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #625d52;
  }

  .carousel {
    width: 100%;
    transform: translateY(-32px);
  }

  .prev {
    z-index: 10;
  }

  @media (max-width: 374px) {
    .carousel {
      width: 100%;
    }
  }

  @media (max-width: 767px) {
    .other {
      display: none;
    }
  }

  @media (min-width: 768px) {
    padding: 94px 48px;
    height: 892px;
    padding-bottom: 144px;
    .title {
      font-size: 40px;
      line-height: 48px;
      margin-bottom: 48px;
      /* margin-top: 56px; */
      padding-top: 96px;
    }

    .title-small {
      font-weight: bold;
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 24px;
    }

    .step {
      margin-bottom: 24px;
      margin-top: 24px;
    }

    .mobile {
      display: none;
    }

    .img1 {
      width: 545px;
      height: 352px;
    }

    .carousel {
      width: 672px;
      width: 100%;
    }

    .number {
      font-weight: 500;
      font-size: 18px;
      line-height: 18px;
      margin-bottom: 24px;
    }

    p {
      width: 480px;
      margin: auto;
    }
  }

  @media (min-width: 1024px) {
    height: 704px;
    .carousel {
      max-width: 1180px;
      width: 100%;
    }

    .img {
      margin-left: 64px;
    }

    .holder {
      display: flex !important;
      flex-direction: row !important;
      justify-content: center !important;
      align-items: center !important;
    }

    .step {
      text-align: left;
    }

    .title-small {
      font-weight: bold;
      font-size: 32px;
      line-height: 40px;
      text-align: left;

      width: auto;
    }

    p {
      font-size: 18px;
      line-height: 24px;
      text-align: left;
      margin: 0;
      overflow-wrap: break-word;
      width: auto;
    }

    .block {
      width: 44%;
      order: -1;
      margin-left: 64px;
    }

    .title {
      font-weight: bold;
      font-size: 48px;
      line-height: 56px;
      margin-top: 32px;
      margin-bottom: 32px;
    }
  }

  @media (min-width: 1376px) {
    /* width: 1376px; */
    height: 704px;
    padding-left: 0;
    padding-right: 0;

    background: linear-gradient(180deg, #e3f9e5 0%, #faf9f7 54.69%);
    border: 1px solid #e8e6e1;
    border-radius: 8px;
    margin: auto;
  }

  @media (min-width: 1440px) {
    height: 704px;
    padding-left: 0;
    padding-right: 0;

    background: linear-gradient(180deg, #e3f9e5 0%, #faf9f7 54.69%);
    border: 1px solid #e8e6e1;
    border-radius: 8px;

    .carousel {
      /* max-width: 1252px;
      width: 1252px; */
    }

    .holder {
      display: flex !important;
      flex-direction: row !important;
      justify-content: center !important;
      align-items: center !important;
    }

    .step {
      text-align: left;
      width: 100%;
    }

    .title-small {
      font-weight: bold;
      font-size: 32px;
      line-height: 40px;
      text-align: left;
      width: 100%;
    }

    p {
      font-size: 18px;
      line-height: 24px;
      text-align: left;
      margin: 0;
      width: 100%;
    }

    .block {
      width: 512px;
      order: -1;
    }

    .title {
      font-weight: bold;
      font-size: 48px;
      line-height: 56px;
      margin-top: 32px;
      margin-bottom: 32px;
    }
  }

  ////
  .slcik-track {
    transition: height 1000ms;
  }
  .slick-arrow {
    width: 48px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .slick-prev:before {
    content: none !important;
  }

  .slick-next:before {
    content: none !important;
  }

  .slick-dots {
    display: flex !important;
    flex-direction: row;
    justify-content: space-evenly !important;
    align-content: center;
    list-style: none;
  }

  .slick-active {
    .paging-holder {
      transition: opacity 1000ms;
      opacity: 1;
    }
  }

  @media (max-width: 767px) {
    .other {
      display: none;
    }
  }

  @media (min-width: 768px) {
    margin-top: 48px;
    .slick-arrow {
      height: 48px;
    }

    .slick-dots {
      transform: translateY(-32px);
    }

    .slick-track {
      height: 664px;
    }

    .slick-arrow {
      transform: translateY(-80px);
    }
  }

  @media (min-width: 1024px) {
    margin-top: 56px;
    .slick-track {
      height: 464px;
    }

    .slick-dots {
      /* padding-left: 7%;
      padding-right: 7%; */
    }

    .img {
      order: -1;
    }

    .block {
      order: 0;
      margin-right: 32px;
      margin-left: 0;
    }
  }

  @media (min-width: 1440px) {
    margin-top: 96px;

    .slick-dots {
      /* padding-left: 8%;
      padding-right: 8%; */
    }

    .slick-track {
      height: 464px;
    }
  }

  .img {
    /* width: 100%; */
    height: auto;
    margin: auto;
  }

  @media (min-width: 1024px) {
    img {
      width: 504px !important;
      margin: 0 !important;
    }
  }
`

const HowDoesItWork = () => {
  const [progress, setProgress] = useState(() => 0)

  function SampleNextArrow(props) {
    const { className, onClick } = props
    return (
      <div
        className={"next " + className}
        onKeyDown={onClick}
        tabIndex={0}
        role="button"
        onClick={onClick}
      >
        <Next className="other" />
        <NextSmall className="mobile" />
      </div>
    )
  }

  function SamplePrevArrow(props) {
    const { className, onClick } = props
    return (
      <div
        className={"prev " + className}
        onKeyDown={onClick}
        tabIndex={0}
        role="button"
        onClick={onClick}
      >
        <Prev className="other" />
        <PrevSmall className="mobile" />
      </div>
    )
  }

  useEffect(() => {
    if (window.innerWidth < 768) {
      for (let i = 0; i < 6; i++) {
        let el = document.querySelector(".paging-holder" + i)
        i <= progress ? (el.style.opacity = 1) : (el.style.opacity = 0.3)
      }
    } else {
      for (let i = 6; i < 12; i++) {
        let el = document.querySelector(".paging-holder" + i)
        i <= progress + 6 ? (el.style.opacity = 1) : (el.style.opacity = 0.3)
      }
    }
  }, [progress])

  const settingsMobile = {
    dots: true,
    arrows: true,
    speed: 1000,
    customPaging: i => {
      return (
        <div className={"paging-holder paging-holder" + i}>
          <div
            className="number"
            onClick={() => setProgress(progress => (progress = i))}
          >
            {i + 1}
          </div>
          <div
            className="line"
            onClick={() => setProgress(progress => (progress = i))}
          ></div>
        </div>
      )
    },
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  }

  const settingsOther = {
    dots: true,
    arrows: true,
    speed: 1000,
    customPaging: (i = 6) => {
      let i2 = i + 6
      return (
        <div className={"paging-holder paging-holder" + i2}>
          <div className="number">Step {i + 1}</div>
          <div
            className="line"
            onClick={() => setProgress(progress => (progress = i))}
          ></div>
        </div>
      )
    },
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  }

  return (
    <AnnualStyle>
      <div className="title">
        <Title title="how it works" />
      </div>
      <div className="carousel">
        <Slider
          {...settingsMobile}
          afterChange={currentSlide => setProgress(prog => currentSlide)}
          className="mobile"
        >
          <div className="holder">
            <StaticImage
              tag="section"
              className="img1 mobile"
              quality={100}
              layout="constrained"
              placeholder="blurred"
              src="../images/how_does_it_work/img1.png"
              alt="Img6"
              toformat="webp"
              loading="eager"
            ></StaticImage>
            <div className="step"> Step 1</div>
            <div className="title-small">
              {" "}
              We talk about
              <br /> your challenges.
            </div>
            <p>
              {" "}
              We discuss your production processes, machinery and challenges on
              the shop floor, in order to propose the most optimal solution for
              you.
            </p>
          </div>
          <div className="holder">
            <StaticImage
              tag="section"
              className="img1 mobile"
              quality={100}
              layout="constrained"
              placeholder="blurred"
              src="../images/how_does_it_work/img2.png"
              alt="Img6"
              toformat="webp"
              loading="eager"
            ></StaticImage>
            {/* <img src={Img2} alt="img2" className="img img1 mobile" /> */}
            <div className="step"> Step 2</div>
            <div className="title-small">
              {" "}
              We analyse your
              <br /> shop floor
            </div>
            <p>
              {" "}
              Together with your production and/or maintenance crew we analyse
              your machinery and other physical aspects of your factory to
              determine best combination of sensors and equipment to give you
              best value.
            </p>
          </div>
          <div className="holder">
            <StaticImage
              tag="section"
              className="img1 mobile"
              quality={100}
              layout="constrained"
              placeholder="blurred"
              src="../images/how_does_it_work/img3.png"
              alt="Img6"
              toformat="webp"
              loading="eager"
            ></StaticImage>
            {/* <img src={Img3} alt="img2" className="img img1 mobile" /> */}
            <div className="step"> Step 3</div>
            <div className="title-small">
              {" "}
              We prepare and present
              <br /> a solution
            </div>
            <p>
              {" "}
              Based on our discussion and analysis, we present what we believe
              is the most suitable mix of hardware and algorithms for you to get
              the best ROI and improve your operation.
            </p>
          </div>
          <div className="holder">
            <StaticImage
              tag="section"
              className="img1 mobile"
              quality={100}
              layout="constrained"
              placeholder="blurred"
              src="../images/how_does_it_work/img4.png"
              alt="Img6"
              toformat="webp"
              loading="eager"
            ></StaticImage>
            <div className="step"> Step 4</div>
            <div className="title-small"> We do the rollout</div>
            <p>
              {" "}
              We help you install all the necessary hardware and start-up the
              data collection and algorithms to get instant feedback and value.
            </p>
          </div>
          <div className="holder">
            <StaticImage
              tag="section"
              className="img1"
              quality={100}
              layout="constrained"
              placeholder="blurred"
              src="../images/how_does_it_work/img5.png"
              alt="Img6"
              toformat="webp"
              loading="eager"
            ></StaticImage>
            <div className="step"> Step 5</div>
            <div className="title-small">
              {" "}
              We inspect the
              <br /> results
            </div>
            <p>
              {" "}
              After a bit of time, we organise a mutual meeting to analyse the
              results of Ascalia rollout, address any questions and discuss any
              new ideas or needs you might need.
            </p>
          </div>
          <div className="holder">
            <StaticImage
              tag="section"
              className="img1 mobile"
              quality={100}
              layout="constrained"
              placeholder="blurred"
              src="../images/how_does_it_work/img6.png"
              alt="Img6"
              toformat="webp"
              loading="eager"
            ></StaticImage>
            <div className="step"> Step 6</div>
            <div className="title-small">
              {" "}
              We follow you on
              <br /> your journey
            </div>
            <p>
              {" "}
              We do not forget about you! Our professional industrial support
              personnel is here to help you anytime you might need it. We are
              also here for you as your factory grows and changes, supporting
              you on your way to dominate your market!
            </p>
          </div>
        </Slider>

        <Slider
          {...settingsOther}
          afterChange={currentSlide => setProgress(prog => currentSlide)}
          className="other"
        >
          <div className="holder">
            <StaticImage
              tag="section"
              className="img1 other"
              quality={100}
              layout="constrained"
              placeholder="blurred"
              src="../images/how_does_it_work/img1_o.png"
              toformat="webp"
              alt="Img6"
              loading="eager"
            ></StaticImage>
            <div className="block">
              <div className="step"> Step 1</div>
              <div className="title-small">We talk about your challenges.</div>
              <p>
                We discuss your production processes, machinery and challenges
                on the shop floor, in order to propose the most optimal solution
                for you.
              </p>
            </div>
          </div>
          <div className="holder">
            <StaticImage
              tag="section"
              className="img1 other"
              quality={100}
              layout="constrained"
              placeholder="blurred"
              src="../images/how_does_it_work/img2_o.png"
              toformat="webp"
              alt="Img6"
              loading="eager"
            ></StaticImage>
            {/* <img src={Img2} alt="img2" className="img img1 other" /> */}
            <div className="block">
              <div className="step"> Step 2</div>
              <div className="title-small">We analyse your shop floor </div>
              <p>
                Together with your production and/or maintenance crew we analyse
                your machinery and other physical aspects of your factory to
                determine best combination of sensors and equipment to give you
                best value.
              </p>
            </div>
          </div>
          <div className="holder">
            <StaticImage
              tag="section"
              className="img1 other"
              quality={100}
              layout="constrained"
              placeholder="blurred"
              src="../images/how_does_it_work/img3_o.png"
              toformat="webp"
              alt="Img6"
              loading="eager"
            ></StaticImage>
            {/* <img src={Img3} alt="img2" className="img img1 other" /> */}
            <div className="block">
              <div className="step"> Step 3</div>
              <div className="title-small">
                We prepare and present a solution
              </div>
              <p>
                Based on our discussion and analysis, we present what we believe
                is the most suitable mix of hardware and algorithms for you to
                get the best ROI and improve your operation.
              </p>
            </div>
          </div>
          <div className="holder">
            <StaticImage
              tag="section"
              className="img1 other"
              quality={100}
              layout="constrained"
              placeholder="blurred"
              src="../images/how_does_it_work/img4_o.png"
              toformat="webp"
              alt="Img6"
              loading="eager"
            ></StaticImage>
            <div className="block">
              <div className="step"> Step 4</div>
              <div className="title-small">We do the rollout </div>
              <p>
                We help you install all the necessary hardware and start-up the
                data collection and algorithms to get instant feedback and
                value.
              </p>
            </div>
          </div>
          <div className="holder">
            <StaticImage
              tag="section"
              className="img1 other"
              quality={100}
              layout="constrained"
              placeholder="blurred"
              src="../images/how_does_it_work/img5_o.png"
              toformat="webp"
              alt="Img6"
              loading="eager"
            ></StaticImage>
            <div className="block">
              <div className="step"> Step 5</div>
              <div className="title-small">We inspect the results</div>
              <p>
                After a bit of time, we organise a mutual meeting to analyse the
                results of Ascalia rollout, address any questions and discuss
                any new ideas or needs you might need.
              </p>
            </div>
          </div>
          <div className="holder">
            <StaticImage
              tag="section"
              className="img1 other"
              quality={100}
              layout="constrained"
              placeholder="blurred"
              src="../images/how_does_it_work/img6_o.png"
              toformat="webp"
              alt="Img6"
              loading="eager"
            ></StaticImage>
            <div className="block">
              <div className="step"> Step 6</div>
              <div className="title-small">We follow you on your journey</div>
              <p>
                We do not forget about you! Our professional industrial support
                personnel is here to help you anytime you might need it. We are
                also here for you as your factory grows and changes, supporting
                you on your way to dominate your market!
              </p>
            </div>
          </div>
        </Slider>
      </div>
    </AnnualStyle>
  )
}

export default HowDoesItWork
